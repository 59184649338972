import React from 'react'

export default function index() {
    return (
        <div id ="footer" className="bg-gray-900 mt-[80px] text-white text-center h-[60px]">
            <div className="pt-4">
                <p> <a href="https://te-consulting.at/impressum/"> Copyright © 2022 TEC Technical Evolution Consulting GmbH - All Rights Reserved.</a></p>
            </div>
            {/* <div> */}
                {/* <p className="mt-16"> */}
                    {/* <span className="underline"> <a href="https://nicepage.com/website-templates"> Website Templates </a></span> */}
                    {/* created with
                    <span className="underline"> <a href="https://nicepage.com/"> Builder Software. </a></span> */}
                {/* </p> */}
            {/* </div> */}
        </div>
    )
}
