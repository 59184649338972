import React, { useState, useRef } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import ContractHelpDialog from '../../components/BasicComponents/HelpModal'
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tab from '@mui/material/Tab';
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress';
import detectEthereumProvider from '@metamask/detect-provider'

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { isBefore, isAfter, getUnixTime } from "date-fns"

require('react-dom');

/*
Streaming Contract Address am Ropsten Testnet
*/
// const streamingContract = '0xd6bA6f28DCF690510d57C4666b59deCFE70f683E'
/*
Streaming Contract Address am Goerli Testnet
*/
const streamingContractGoerli = '0x606E020F0f5C8260DdDd07AE842F776744B69548'

/*
Beispiel Addressen um den Nutzer zu unterstützen
Beispielsweise neue Nutzer für ein Vote anzumelden
Jedoch nicht im Interface implementiert
Derzeit haben diese keine Funktion
*/
// const exampleAccs = [
//   '0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2',
//   '0x4B20993Bc481177ec7E8f571ceCaE8A9e22C02db',
//   '0x78731D3Ca6b7E34aC0F824c42a7cC18A495cabaB',
//   '0x617F2E2fD72FD9D5503197092aC168c91465E7f2',
// ]

//Definieren der contract variable, dass diese Exisitiert
let contract

/*
initiale Rendervariable;
Dient als Dummy Daten, wenn der Nutzer noch keine Interaktion mit
dem Smart Contract gehabt hat
*/
let renderData = [{
  name: "You do not participate in any stream",
  id: "",
  streamSender: "",
  streamReceiver: "",
  streamStartTime: "",
  streamEndTime: "",
  cancelTime: "",
  amountToStream: "",
  streamedAmount: "",
  amountSender: "",
  amountReceiver: "",
  streamStatus: "",
  contractOwner: "",
}]

//Definieren der provider variable
let provider

//Definieren der active Contract Id
let activeContractId

/*
Definieren des retrunData Objectes
Unterscheidung zwischen
  own (Voting selbst erstellt)
  particiapte (Teilnehmer bei einem Vote)
Wird unterschieden, damit nur die möglichen Funktionen im Interface dargestellt werden
*/
let returnData = {
  own: new Array(),
  participate: new Array()
}

const Web3 = require('web3')
const HDWalletProvider = require('@truffle/hdwallet-provider')
const MyContract = require('../../build/contracts/StreamingPayments.json')

async function initializeSmartContract() {
  /*
  Ethereum Provider finden
  Funktion schaut, ob MetaMask vorhanden ist
  Wird als Schnittstelle für Blockchain Queries verwendet
  */
  provider = await detectEthereumProvider()

  //Wenn provider vorhanden
  if (provider) {
    // From now on, this should always be true:
    // provider === window.ethereum
    const web3 = new Web3(provider)

    console.log('Loading contracts from adress...')
    //contract Informationen laden
    contract = new web3.eth.Contract(
      MyContract.abi,
      streamingContractGoerli,
    )
    console.log('Contracts ready!\n')

    return contract

  } else {
    console.log('Please install MetaMask!')

    return undefined
  }
}

/*
Delay Funktion um in async Codestellen zu Warten
*/
async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/*
Linear Progress wird verwendet um den Voting Progress
zwischen Ja und Nein Votes darzustellen
*/
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: "70%", margin: "auto" }}>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary"></Typography>
      </Box>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${100 - (Math.round(
          props.value,
        ))}%`}</Typography>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary"></Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

//Von Ether zu Wei Wert umrechnen
function toWei(value) {
  return value * 1000000000000000000
}

//Von Wei zu Ether Wert umrechnen
function toEth(value) {
  return value / 1000000000000000000
}

// STREAMING CONTRACT FUNCTIONS

/*
Alles Funktionen um mit dem Smart Contract zu interagieren
Bestehen aus diversen Error Checks und entwender aus einem:
  -> .send(on chain transaktion)
  -> .call(daten von chain auslesen)

.call besitzen return wert
.send kein return wert, lösen aber ein reloading aus
*/
async function userCreateStream(name, receiver, start, end, ethAmount) {
  if (isBefore(start, end) && isAfter(end, start)) {
    ethAmount = toWei(ethAmount)

    await contract.methods
      .newStream(name, receiver, start, end)
      .send({
        from: window.ethereum.selectedAddress,
        value: ethAmount
      })
    return true
  }
  else {
    return "start time is after endtime"
  }
}

async function cancelActiveStream(streamId) {
  return await contract.methods
    .cancelStream(streamId)
    .send({ from: window.ethereum.selectedAddress })
}

async function doWithdrawEthFromStream(streamId) {
  return await contract.methods
    .withdrawEthFromStream(streamId)
    .send({ from: window.ethereum.selectedAddress })
}

async function allStreamsFromHolder(addr) {
  return await contract.methods
    .allStreamsFromHolder(addr)
    .call({ from: window.ethereum.selectedAddress })
}

async function allStreamsHolderParticipates(addr) {
  return await contract.methods
    .allStreamsHolderParticipates(addr)
    .call({ from: window.ethereum.selectedAddress })
}

async function blockTime() {
  return await contract.methods
    .blockTime()
    .call({ from: window.ethereum.selectedAddress })
}

async function getContractOwner() {
  return await contract.methods
    .contractOwner()
    .call({ from: window.ethereum.selectedAddress })
}

async function getStreamCount() {
  return await contract.methods
    .streamCount()
    .call({ from: window.ethereum.selectedAddress })
}

async function getStreamedAmount(streamId) {
  return await contract.methods
    .streamedAmount(streamId)
    .call({ from: window.ethereum.selectedAddress })
}

async function getStream(streamId) {
  return await contract.methods
    .streams(streamId)
    .call({ from: window.ethereum.selectedAddress })
}

/*
Lädt Informaitonen auf grund der vorgegeben pollId
und fügt es zu own oder participate des returnData Objectes hinzu
*/
async function getStructuredObject(streamId, type) { //type is own or participate
  let dataObject = await getStream(streamId)
  let contractOwner = await getContractOwner()
  let streamedAmount = await getStreamedAmount(streamId)

  let amountSender = (dataObject.streamEnded === true && toEth(dataObject.amountSender) === 0) ?
    (toEth(dataObject.amountSender) + " - (" + toEth(dataObject.toStream - streamedAmount) + " already withdrawn)")
    :
    toEth(dataObject.toStream - streamedAmount)

  let amountReceiver = (dataObject.streamEnded === true && toEth(dataObject.amountReceiver) === 0) ?
    (toEth(dataObject.amountReceiver) + " - (" + toEth(streamedAmount) + " already withdrawn)")
    :
    toEth(streamedAmount)

  let streamStatus = (dataObject.cancelled === true && dataObject.streamEnded === true) ?
    "cancelled + ended"
    :
    (Date.now() > parseFloat(dataObject.end) * 1000) ?
      "ended"
      :
      (Date.now() < parseFloat(dataObject.start) * 1000) ?
        "starting"
        :
        "active"

  let structuredObject = {
    name: dataObject.streamName,
    id: streamId,
    streamSender: dataObject.sender,
    streamReceiver: dataObject.receiver,
    streamStartTime: new Date(dataObject.start * 1000).toISOString().slice(0, 19).replace('T', ' '),
    streamEndTime: new Date(dataObject.end * 1000).toISOString().slice(0, 19).replace('T', ' '),
    cancelTime: (parseInt(dataObject.cancelledTime) !== 0) ? new Date(dataObject.cancelledTime * 1000).toISOString().slice(0, 19).replace('T', ' ') : "no cancel time",
    amountToStream: toEth(dataObject.toStream),
    streamedAmount: toEth(streamedAmount),
    amountSender: amountSender,
    amountReceiver: amountReceiver,
    streamStatus: streamStatus,
    contractOwner: contractOwner,
  }

  returnData[type].push(structuredObject)
}

/*
Fragt alle ids von Stremans an welchen der Nutzer Teilnimmt
Speichert diese als Array in:
  streamIdsOwn
  streamIdsReceiver
Mittels Loop werden alle notwendigen Informationen durch die Funktion
getStructuredObject(..) über die Blockchain gequeried

Solange die Daten asynchron geladen werden, wartet die Funktion
Nachdem alle Daten geladen wurden (Länge von ids und returnData Objekt stimmen überein)
werdne die geladenen Daten zurückgegeben
*/
async function getStreamDataFromAddr(addr) {
  let streamIdsOwn = await allStreamsFromHolder(addr)
  let streamIdsReceiver = await allStreamsHolderParticipates(addr)

  for (let key in streamIdsOwn) {
    getStructuredObject(streamIdsOwn[key], "own")
  }

  for (let key in streamIdsReceiver) {
    getStructuredObject(streamIdsReceiver[key], "participate")
  }

  while (returnData.own.length !== streamIdsOwn.length ||
    returnData.participate.length !== streamIdsReceiver.length) {
    await delay(200)
  }

  return returnData
}

/*
Ermittelet die akutelle Id der jeweils aktiven Share
unterscheidet beim Ermitteln, ob basierend auf der Obj ID gesucht wird oder nicht
*/
function activeObjectLength(activeObj, byObjId) {
  if (byObjId === true) {
    for (let key in renderData) {
      if (renderData[key].id === activeObj.id) {
        return key
      }
    }
    return renderData.length
  }
  else {
    for (let key in renderData) {
      if (JSON.stringify(renderData[key]) === JSON.stringify(activeObj)) {
        return key
      }
    }
    return 0
  }
}

/*
Bubble Sort Funktion, um die asynchron geladnenen Daten von returnObjekt
aufgrund der Id in eine aufsteigende Reihenfolge zu übeführen
*/
function sortArray(array) {
  let swapped = true;
  while (swapped) {
    swapped = false;
    for (let j = 0; j < array.length - 1; j++) {
      if (array[j].id > array[j + 1].id) {
        let temp = array[j];
        array[j] = array[j + 1];
        array[j + 1] = temp;
        swapped = true;
      }
    }
  }
  return array;
}

/*
neu geladene Daten werden in Struktur gebracht und
in der renderData Variable gespeichert

*Hint: Eventuell falsch implementiert und könnte mittels einer State Variable mit Set Funktion ersetzt werden
       Eine der Kinderkrankheiten der ersten React Schritte
*/
function setData(data) {
  renderData = sortArray(data)
}

// TAB-PANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <h1>{children}</h1>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/*
React Componente StreamingInterface
beinhaltet alle notwenidgen Logikschritte, um das Interface entsprechend aufzubauen
basierend auf stateVariablen und useEffect hooks wird das ordentliche Laden sichergestellt
*/
export default function StreamingInterface({ lang }) {
  const [progressBarValue, setProgressBarValue] = useState(0) //progress bar standard value
  const [isLoading, setLoading] = useState(true); //contract daten werden geladen - bzw. sind geladen
  const [isProcessing, setProcessing] = useState(false); //daten werden verarbeitet (smart contract call)
  const [init, setInit] = useState(false) //initales daten laden abgeschlossen
  const [newContract, setNewContract] = useState(true); //contract daten wurden geupdated
  const [tabValue, setTabValue] = React.useState(0); //aktuell zu rendernder tab
  const [activeObj, setActiveObj] = useState(renderData[0]) //speichern des aktiven Objektes (ausgewählte poll daten)
  const [oldObj, setOldObj] = useState(renderData[0]) //speichern des vorherigen poll Objektes
  const [newData, setNewData] = useState(false) //neu geladene daten
  const [renderType, setRenderType] = useState(false) //false -> initial und bei neuer share | true -> bei call einer share
  const [ownOrParticipate, setOwnOrParticipate] = useState("participate") //ob own oder participate angezeigt werden soll

  // InputValidation Statevars CREATE STREAM
  const [inputNameValid, setInputNameValid] = useState(false);
  const [inputAddressValid, setInputAddressValid] = useState(false);
  const [inputEthAmountValid, setInputEthAmountValid] = useState(false);
  const [inputDateValid, setInputDateValid] = useState(true);
  const [inputErrorNumeric, setInputErrorNumeric] = useState("");
  const [inputErrorReceiverAddressEn, setInputErrorReceiverAddressEn] = useState('(e.g. "0x1f07208802d...")');
  const [inputErrorReceiverAddressDe, setInputErrorReceiverAddressDe] = useState('(z.B. "0x1f07208802d...")');
  const [startTimeError, setStartTimeError] = useState('');

  // InputValidation Statevars MANAGE STREAMS
  const [inputErrorEthAmountEn, setInputErrorEthAmountEn] = useState('Enter Amount in ETH (e.g. "0.5")');
  const [inputErrorEthAmountDe, setInputErrorEthAmountDe] = useState('Betrag in Ethereum (z.B. "0.5")');

  const [radioValue, setRadioValue] = useState('')
  const [startTime, setStartTime] = useState(Date.now())
  const [endTime, setEndTime] = useState(Date.now() + 10800000)

  /*
  states updaten, wenn der Tab gewechselt wird
  -> own wenn tab value 1 ist
  -> particiapte wenn tab value 0 oder 2 ist
  */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setInputNameValid(false);
    setInputAddressValid(false);
    setInputDateValid(true);
    setInputEthAmountValid(false);
    setInputErrorEthAmountDe('Betrag in Ethereum (z.B. "0.5")');
    setInputErrorEthAmountEn('Enter amount in ETH (e.g. "0.5")');
    setInputErrorReceiverAddressDe('(z.B. "0x1f07208802d...")');
    setInputErrorReceiverAddressEn('(e.g. "0x1f07208802d...")');
    setStartTime(Date.now())
    setEndTime((Date.now() + 10800000))
    setStartTimeError('')
  };

  // Input Validation Tab1(Create Stream)
  const handleNameInputChange = (event) => {
    const { target: { value } } = event;
    if (!value) {
      setInputNameValid(false);
    }
    else {
      setInputNameValid(true);
    }
  }

  //Handle Eth Amount Change
  const handleEthAmountInputChange = (event) => {
    const { target: { value } } = event;
    setInputErrorEthAmountDe("");
    setInputErrorEthAmountEn("");
    let reg = new RegExp(/^\d+\.?\d*$/).test(value);
    if (!reg) {
      setInputErrorEthAmountEn('Please enter numeric value (e.g. "0.5")');
      setInputErrorEthAmountDe('Bitte numerischen Wert angeben (z.B.: "0.5")');
      setInputEthAmountValid(false);
    }
    else {
      setInputErrorEthAmountEn('Enter Amount in ETH (e.g. "0.5")');
      setInputErrorEthAmountDe('Betrag in Ethereum (z.B. "0.5")');
      setInputEthAmountValid(true);
    }
    if (value === "") {
      setInputErrorEthAmountEn('Enter Amount in ETH (e.g. "0.5")');
      setInputErrorEthAmountDe('Betrag in Ethereum (z.B. "0.5")');
    }
  }

  //Handle Address Change
  const handleAddressInputChange = (event) => {
    const { target: { value } } = event;
    setInputErrorReceiverAddressDe("");
    setInputErrorReceiverAddressEn("");
    let reg = new RegExp(/^0x[a-fA-F0-9]{40}$/).test(value);
    if (!reg) {
      setInputErrorReceiverAddressEn('Enter valid address (e.g. "0x1f07208802d...")');
      setInputErrorReceiverAddressDe('Enter valid address (e.g. "0x1f07208802d...")');
      setInputAddressValid(false);
    }
    else {
      setInputErrorReceiverAddressDe("");
      setInputErrorReceiverAddressEn("");
      setInputAddressValid(true);
    }
  }

  //Handle Date Change
  function handleStartDateInputChange(newValue) {
    console.log(newValue)
    if (newValue >= endTime) {
      setInputDateValid(false);
      setStartTimeError("start must be before end")
    }
    else {
      setInputDateValid(true);
      setStartTimeError("")
    }
  }

  //Handle Date Change
  function handleEndDateInputChange(newValue) {
    console.log(newValue)
    if (newValue <= startTime) {
      setInputDateValid(false);
      setStartTimeError("end must be after start")
    }
    else {
      setInputDateValid(true);
      setStartTimeError("")
    }
  }

  // Input Field Refs
  const vCreateStreamName = useRef('')
  const vCreateReceiver = useRef('')
  const vCreateEthAmount = useRef('')

  /*
  DOM Werte updaten:
      -> wenn ein update durch den Smart Contract stattfindet
      -> wenn tabswitch zwischen own und participate bereich stattfindet
      -> wenn ausgewähltes object (eigenes Voting) nicht dem vorherigen object entspricht; bedeutet actives object wurde geupdated
  */
  function updateDocumentData(obj, byNewUpdate, activeObj = undefined) {
    obj = (byNewUpdate === true) ? renderData[activeObjectLength(activeObj, true)] : obj
    activeContractId = obj.id

    document.getElementById(
      'name',
    ).innerHTML = `${obj.name}`

    document.getElementById(
      'id',
    ).innerHTML = `${obj.id}`

    document.getElementById(
      'streamSender',
    ).innerHTML = `${obj.streamSender}`

    document.getElementById(
      'streamReceiver',
    ).innerHTML = `${obj.streamReceiver}`

    document.getElementById(
      'streamStartTime',
    ).innerHTML = `${obj.streamStartTime}`

    document.getElementById(
      'streamEndTime',
    ).innerHTML = `${obj.streamEndTime}`

    document.getElementById(
      'cancelTime',
    ).innerHTML = `${obj.cancelTime}`

    document.getElementById(
      'amountToStream',
    ).innerHTML = `${obj.amountToStream}`

    document.getElementById(
      'streamedAmount',
    ).innerHTML = `${obj.streamedAmount}`

    document.getElementById(
      'amountSender',
    ).innerHTML = `${obj.amountSender}`

    document.getElementById(
      'amountReceiver',
    ).innerHTML = `${obj.amountReceiver}`

    document.getElementById(
      'streamStatus',
    ).innerHTML = `${obj.streamStatus}`

    document.getElementById(
      'contractOwner',
    ).innerHTML = `${obj.contractOwner}`

    //handle no votes (zero division)
    let completion = ((parseFloat(obj.streamedAmount) / parseFloat(obj.amountToStream)) * 100).toFixed(2)

    setProgressBarValue(completion)
    setActiveObj(obj);
  }

  /*
  Wenn newContract == true wird der contract zum ersten mal initial geladen
  bedeutet Objektinformationen komplett abgreifen, wenn vorhanden diese anzeigen ansonsten das sampleObject verwerden
  State Varialben entsprechend setzen
  */
  if (newContract === true) {
    initializeSmartContract()
      .then(contr => {
        contract = contr
        console.log("contract", contract)
        getStreamDataFromAddr(window.ethereum.selectedAddress).then(data => {
          returnData = {
            own: new Array(),
            participate: new Array()
          }

          let concArray = data["own"].concat(data["participate"])

          if (concArray.length !== 0) {
            setData(concArray);
            if (newContract === true && init === true) {
              console.log("new contract")
              console.log(data, ownOrParticipate)
              setActiveObj(concArray[concArray.length - 1])
              setNewContract(false)
            }
          }
          else {
            let sampleData = [{
              name: "You do not participate in any stream",
              id: "",
              streamSender: "",
              streamReceiver: "",
              streamStartTime: "",
              streamEndTime: "",
              cancelTime: "",
              amountToStream: "",
              streamedAmount: "",
              amountSender: "",
              amountReceiver: "",
              streamStatus: "",
              contractOwner: "",
            }]

            setData(sampleData);

            if (newContract === true && init === true) {
              setActiveObj(sampleData[0])
              setNewContract(false)
            }
          }

          setNewContract(false)
          setInit(true)
          setLoading(false);
          setProcessing(false)
        })
      })
  }

  /*
  Wenn die Daten im Contract geupdated werden, ist newData true
  Dabei müssen die neuen Daten geladen werden; neuer Call an smart contract
  Daten müssen dann entsprechend in DOM eingebettet werden
  State Variablen für weiteres rendern richtig setzen
  */
  if (newData === true) {
    getStreamDataFromAddr(window.ethereum.selectedAddress).then(data => {
      returnData = {
        own: new Array(),
        participate: new Array()
      }
      let concArray = data["own"].concat(data["participate"])

      if (concArray.length !== 0) {
        setData(concArray);
        updateDocumentData(activeObj, true, activeObj)
        setProcessing(false)
      }
    })
    setNewData(false)
  }

  /*
  Effect der bei neuem rendern der Seite vergleicht, ob sich das activeObject geändert hat
  Bei Änderung wird DOM elemente geupdated, und das oldObject mit activeObject gleichgesetzt
  */
  React.useEffect(() => {
    if (JSON.stringify(oldObj) !== JSON.stringify(activeObj)) {
      updateDocumentData(activeObj, false)
      setOldObj(activeObj)
    }
  })

  /*
  Rendering des DOM;
  isLoading -> während daten geladen werden
  ownOrParticipate -> führt im FrontEnd dazu, dass im Autocomplete nur die tatsälich möglichen Votes
                      für die jeweilgen Operationen angezeigt werden

  wird per if Funktion festgelegt, da sonst kein update durch ein stateupdate durchgeführt wird
        -> sprich Änderungen immer in beiden Teilen der If Funktionen durchführen

  bei Button clicks werden entsprechend die States gestetzt, um bei einem Update die richtigen UI Elemente
  wie bspw. Goerli Testnet processing darzustellen.
  */
  if (isLoading) {
    return <CircularProgress />;
  }
  else {
    //avoid having wrong renderData as activeObj; BigNumber Issue
    if (activeObj.id === "" && renderData[0].id !== "") setActiveObj(renderData[0])

    return (
      <div>
        <Grid container>
          <Grid item xs={12} s={6} md={6}>
            <Box display={"flex"} mt={6} pl={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                defaultValue={renderData[0]}
                value={(renderType === false) ? renderData[activeObjectLength(activeObj, false)] : renderData[activeObjectLength(activeObj, true)]}
                onChange={(e, obj, reason) => {
                  if (obj !== null) {
                    setActiveObj(obj)
                  }
                }}
                options={renderData}
                getOptionLabel={(option) => option.name}
                sx={{ width: 330, color: '#FFFFFF' }}
                renderInput={params => <TextField {...params} label="Streams" />}
              />
            </Box >
            <Box p={2}>
              <Table size="small" aria-label="streaming info">
                <TableBody>
                  <TableRow><TableCell>{lang === "de" ? "Stream Name:" : "Stream name:"} </TableCell><TableCell id="name">{renderData[0].name}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === "de" ? "Stream Id:" : "Stream id:"}</TableCell><TableCell id="id">{renderData[0].id}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === "de" ? "Absender:" : "Sender:"}</TableCell><TableCell id="streamSender">{renderData[0].streamSender}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === "de" ? "Empfänger:" : "Receiver:"} </TableCell><TableCell id="streamReceiver">{renderData[0].streamReceiver}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === "de" ? "Start:" : "Stream starting at:"} </TableCell><TableCell id="streamStartTime">{renderData[0].streamStartTime}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === "de" ? "Ende:" : "Stream ending at:"} </TableCell><TableCell id="streamEndTime">{renderData[0].streamEndTime}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === "de" ? "Stream unterbrochen am:" : "Stream cancel time:"} </TableCell><TableCell id="cancelTime">{renderData[0].cancelTime}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === "de" ? "Übertragung ausstehend:" : "Total amount to stream:"} </TableCell><TableCell id="amountToStream">{renderData[0].amountToStream}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === "de" ? "Bereits übertragen:" : "Already streamed:"} </TableCell><TableCell id="streamedAmount">{renderData[0].streamedAmount}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === "de" ? "Menge Absender:" : "Current amount sender:"} </TableCell><TableCell id="amountSender">{renderData[0].amountSender}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === "de" ? "Menge Empfänger:" : "Current amount receiver:"} </TableCell><TableCell id="amountReceiver">{renderData[0].amountReceiver}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === "de" ? "Stream Status:" : "Stream Status:"} </TableCell><TableCell id="streamStatus">{renderData[0].streamStatus}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === "de" ? "Vertragseigentümer:" : "Contract owner:"} </TableCell><TableCell id="contractOwner">{renderData[0].contractOwner}</TableCell></TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box>
              <h1>Stream completed:</h1>
              <LinearProgressWithLabel id='progressbar' value={progressBarValue} />
            </Box>
          </Grid>
          <Grid item xs={12} s={6} md={6}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange} centered>
                <Tab label={lang === "de" ? "Stream erstellen" : "New stream"} {...a11yProps(0)} />
                <Tab label={lang === "de" ? "Aktive Streams verwalten" : "Manage active streams"} {...a11yProps(1)} />
                <Tab label={lang === "de" ? "Beendete Streams verwalten" : "Manage finished streams"} {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Grid container justifyContent="flex-end">
                <Box mr={4}>
                  <ContractHelpDialog lang={lang} contract={"streaming"} tab={tabValue}></ContractHelpDialog>
                </Box>
              </Grid>
              <Box sx={{ margin: "10px 0 20px 0" }}>
                <TextField
                  onChange={handleNameInputChange}
                  label="Name"
                  size="small"
                  margin="dense"
                  inputRef={vCreateStreamName}
                />
                <TextField
                  onChange={handleAddressInputChange}
                  label={lang === "en" ? "Receiver" : "Empfänger"}
                  size="small"
                  margin="dense"
                  inputRef={vCreateReceiver}
                  helperText={lang === "de" ? inputErrorReceiverAddressDe : inputErrorReceiverAddressEn}
                />
                <TextField
                  onChange={handleEthAmountInputChange}
                  label={lang === "en" ? "ETH Amount" : "ETH Menge"}
                  size="small"
                  margin="dense"
                  helperText={lang === "de" ? inputErrorEthAmountDe : inputErrorEthAmountEn}
                  inputRef={vCreateEthAmount}
                />
                <Box mt={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      renderInput={(props) => <TextField helperText={startTimeError} {...props} />}
                      label={lang === "en" ? "Stream start time" : "Streamstart"}
                      value={startTime}
                      onChange={(newValue) => {
                        setStartTime(newValue.getTime());
                        handleStartDateInputChange(newValue)
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      label={lang === "en" ? "Stream end time" : "Streamende"}
                      value={endTime}
                      onChange={(newValue) => {
                        setEndTime(newValue.getTime());
                        handleEndDateInputChange(newValue)
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Button
                  disabled={!(inputDateValid && inputNameValid && inputEthAmountValid && inputAddressValid)}
                  sx={{ margin: "8px 0 0 0" }}
                  onClick={async () => {
                    try {
                      setProcessing(true)
                      await userCreateStream(
                        vCreateStreamName.current.value,
                        vCreateReceiver.current.value,
                        getUnixTime(startTime),
                        getUnixTime(endTime),
                        vCreateEthAmount.current.value,
                      )
                      setProcessing(false)
                      setRenderType(false)
                      setNewContract(true)
                    }
                    catch {
                      setProcessing(false)
                    }

                  }}
                >
                  {lang === "de" ? "Stream erstellen" : "Create Stream"}
                </Button>
              </Box>
              {/* <Button
                onClick={async () => {
                  const streamCount = await getStreamCount()
                  document.getElementById(
                    'streamCount',
                  ).innerHTML = `Total number of streams: ${streamCount}`
                }}
              >
                Get the total number of streams
              </Button>
              <div id="streamCount">Total number of streams: </div> */}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Container>
                <Grid container justifyContent="flex-end">
                  <Box mr={4}>
                    <ContractHelpDialog lang={lang} contract={"streaming"} tab={tabValue}></ContractHelpDialog>
                  </Box>
                </Grid>
                <Button
                  // disabled={regHolderEnabled}
                  sx={{ margin: "8px 0 0 0" }}
                  onClick={async () => {
                    try {
                      setProcessing(true)
                      await cancelActiveStream(activeObj.id)
                      setProcessing(false)
                      setNewData(true)
                      setRenderType(true)
                    }
                    catch {
                      setProcessing(false)
                    }
                  }}
                >
                  {lang === "de" ? "Stream abbrechen" : "Cancel Stream"}

                </Button>
                <br />
                <Button
                  // disabled={unregHolderEnabled}
                  sx={{ margin: "8px 0 0 0" }}
                  onClick={async () => {
                    try {
                      setProcessing(true)
                      await getStreamDataFromAddr(window.ethereum.selectedAddress)
                        .then(data => {
                          returnData = {
                            own: new Array(),
                            participate: new Array()
                          }
                          let concArray = data["own"].concat(data["participate"])

                          if (concArray.length !== 0) {
                            setData(concArray);
                          }
                        })
                      setProcessing(false)
                      setNewData(true)
                      setRenderType(true)
                    }
                    catch {
                      setProcessing(false)
                    }
                  }}
                >
                  {lang === "de" ? "Stream Daten aktualisieren" : "Refresh stream data"}

                </Button>
              </Container>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Grid container justifyContent="flex-end">
                <Box mr={4}>
                  <ContractHelpDialog lang={lang} contract={"streaming"} tab={tabValue}></ContractHelpDialog>
                </Box>
              </Grid>
              <Button
                disabled={false}
                //true, wenn activer account nicht sender oder receiver
                //&& !activeObj.status.includes("ended"), der status des streams nicht "ended enthält"
                sx={{ margin: "16px 0 0 0" }}
                onClick={async () => {
                  try {
                    setProcessing(true)
                    await doWithdrawEthFromStream(
                      activeObj.id
                    )
                    setProcessing(false)
                    setRenderType(true)
                    setNewData(true)
                    setNewContract(true)
                  }
                  catch {
                    setProcessing(false)
                  }
                }}
              >
                {lang === "de" ? "" : "Withdraw"} {(window.ethereum.selectedAddress.toLowerCase() === activeObj.streamReceiver.toLowerCase()) ? activeObj.amountReceiver : activeObj.amountSender} {lang === "de" ? "ETH vom Stream abheben" : "ETH from the Stream"}
              </Button>
            </TabPanel>
            <span>
              {isProcessing === true && (
                <Box display="flex" alignItems="center" justifyContent="center" m={3}>
                  <Alert sx={{
                    width: "fit-content",
                  }}
                    severity="warning">
                    <AlertTitle>The Goerli Testnet is processing...</AlertTitle>
                    <Box><CircularProgress /></Box>
                    The data displayed might not be up-to-date
                  </Alert>
                </Box>

              )}
            </span>
          </Grid>
        </Grid>

      </div>
    )
  }
}
