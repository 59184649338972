import React, { Component } from "react";
import './navbar.module.css'
import styles from './navbar.module.css'
import StepProgressBar from "../react-step-progress";
import '../react-step-progress/index.css';

export default class CustomNavbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step: props.step
        }
        this.props = {
            lang: props.lang,
        }
    }


    render() {
        return (

            <div className={styles.multiStepContainer}>
                {this.props.lang === "en" && (
                    <StepProgressBar
                        startingStep={this.props.step}
                        steps={[
                            {
                                label: "Connect via Metamask\u00A9",
                                name: "Connect via Metamask"
                            },
                            {
                                label: "Get Goerli Test-Ether",
                                name: "Get Test-Ether"
                            },
                            {
                                label: "Interact with our contracts",
                                name: "Interact with our contracts"
                            }
                        ]}
                        buttonWrapperClass={styles.noButtons}
                    />

                )}
                {this.props.lang === "de" && (
                    <StepProgressBar
                        startingStep={this.props.step}
                        steps={[
                            {
                                label: "Mit Metamask\u00A9 verbinden",
                                name: "Connect via Metamask"
                            },
                            {
                                label: "Goerli Ethereum erhalten",
                                name: "Get Test-Ether"
                            },
                            {
                                label: "Interagieren Sie mit unseren Contracts",
                                name: "Interact with our contracts"
                            }
                        ]}
                        buttonWrapperClass={styles.noButtons}
                    />

                )}
            </div >
        )
    }
}
