import React from 'react'
import Link from '@mui/material/Link';
import Axios from "axios";
import { Button } from '@material-ui/core'
import { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import UserCard from "../BasicComponents/UserCard/UserCard"

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';

const GetTestEther = ({ lang }) => {
    const [isProcessing, setProcessing] = useState(false)

    async function SendGoerliTest(event) {
        setProcessing(true)
        event.currentTarget.disabled = true;
        Axios({
            method: "post",
            url: "/sendtx",
            baseURL: "https://new-blocks-backend.vercel.app/",
            // baseURL: "http://localhost:5000",
            params: {
                receiver: window.ethereum.selectedAddress
            },
        }).then(res => {
            console.log(res);
            // setProcessing(false)
        });

    }

    return (
        <Box p={2}>
            <Divider></Divider>
            <Box m={2}>
                <UserCard lang={lang}></UserCard>
            </Box>
            <Box m={"auto"} maxWidth="1000px">
                <Grid container justifyContent="center">
                    <Alert sx={{
                        width: "fit-content",
                    }}
                        severity="info">
                        <AlertTitle>
                            <Box>
                                <Typography>{lang === 'en' ? 'The wallet you logged in to currently does not own over 0.05 Goerli Ethereum. Visit one of the available public faucet pages or click the button below to apply for 0.1 Goerli Ethereum from our custom private faucet.' : 'Das Wallet mit dem Sie sich angemeldet haben enthält nicht mehr als 0.05 Ethereum. Besuchen Sie eine der öffentlichen Goerli Ethereum Faucet Seiten oder klicken Sie auf die Schaltfläche unten, um 0.1 Ethereum über unser privates Faucet zu erhalten.'}</Typography>
                            </Box>
                        </AlertTitle>
                    </Alert>
                </Grid>
            </Box>

            <Box m={2}>
                <Button style={{
                    borderRadius: 35,
                    backgroundColor: "#387CF6",
                    padding: "12px 26px",
                    fontSize: "16px"
                }} onClick={SendGoerliTest}>{lang === 'en' ? 'I need GOETH!' : 'Ich brauche GOETH!'}</Button>
            </Box>
            <span>
                {isProcessing === true && (
                    <Box m={"auto"} maxWidth="1000px" display="flex" alignItems="center" justifyContent="center">
                        <Alert sx={{
                            width: "fit-content",
                        }}
                            severity="warning">
                            <AlertTitle>{lang === 'en' ? 'The Goerli Testnet is processing...' : 'Transaktion wird bearbeitet...'}</AlertTitle>
                            <Box><CircularProgress /></Box>
                            {lang === 'en' ? 'This might take a few minutes. Please hang tight. You should soon receive your Goerli Ethereum. You will be redirected as soon as the transaction is completed.' : 'Dies kann ein paar Minuten dauern. Bitte bleiben Sie geduldig. Sie werden automatisch weitergeleitet, sobald die Transaktion abgeschlossen ist und Sie Ihre 0.1 GOETH erhalten haben.'}
                        </Alert>
                    </Box>

                )}
            </span>
        </Box >
    )
}

export default GetTestEther
