import React, { useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom';
import pic from '../../assets/pic.jpg'
import aiPic from '../../assets/AI_image.jpg'
import { Container } from '@material-ui/core';
import { Box } from '@material-ui/core';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

export default function Section3({ lang, contractHandler }) {
    const buttonClickHandlerVoting = () => {
        contractHandler(2)
    }
    const buttonClickHandlerDistribution = () => {
        contractHandler(3)
    }
    const buttonClickHandlerStreaming = () => {
        contractHandler(4)
    }
    // useEffect(() => {
    //     window.onscroll = handleScrolle
    // }, [])
    // const handleScrolle = (e) => {
    //     var position = section1Ref.current.getBoundingClientRect();
    //     if (position.top < window.innerHeight && position.bottom >= 0) {
    //         section1Ref.current.classList.add("fade");
    //     } else {
    //         section1Ref.current.classList.remove("fade");
    //     }
    // }
    // var section1Ref = useRef(null);
    const lightTheme = createTheme({ palette: { mode: 'light' } });
    return (
        <div id="section3" className="container-div mt-[50px]">
            <div className="text-center">
                <h1 className="font-bold text-4xl text-blue-400"> {lang === 'en' ? '...or try our AI integration ' : '...oder probieren Sie unsere AI Integration'} </h1>
          {/*      <p className="text-2xl mt-4">  {lang === 'en' ? 'DALL E 2 is a new AI system that can create realistic images and art from a description in natural language.' : 'DALL E 2 ist ein neues KI-System, das aus einer Beschreibung in natürlicher Sprache realistische Bilder und Kunstwerke erstellen kann.'} </p>  */}
            </div>
        <Box  
                sx={{
                    height: 233,
                    width: 350,
                    marginTop: "10px",
                    margin: "auto",

                }}>
                <ThemeProvider theme={lightTheme}>
                <Paper elevation={3}>
                    <img src={aiPic} className='rounded-lg' />
          {/*          <Typography component="h2" >
                        Artful Genie
            </Typography>  */}
               
               </Paper>
                </ThemeProvider>
                    </Box> 
                    <div className="mt-8">
                        <h2 className="text-2xl font-bold"> {lang === 'en' ? 'AI Image Generation' : 'AI Bilder erstellen'} </h2>
                        <p className="mt-3"> {lang === 'de' ? 'Erstellen Sie Bilder und Kunstwerke aus einer Beschreibung in natürlicher Sprache' : 'Create realistic images and art from a description in natural language'}</p>
                        <button onClick={() =>
                            window.open(
                                'https://artfulgenie.newblocks.eu',
                                '_blank' // <- This is what makes it open in a new window.
                            )
                        } className="mt-2 mb-2 rounded-3xl hover:bg-gray-600 bg-blue-400 py-1 px-6 text-white font-semibold">
                            {lang === 'en' ? 'Explore' : 'Erkunden'}
                        </button>
                    </div>

        
        </div>
    )
}
