import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../../assets/logo.png'
export default function index({lang}) {
    return (
        <>
            <div className="bg-header header-container-div shadow-header flex items-center">
                <div href="url('')">
                    <Link to="/">
                        <img className="image-div w-[300px] h-[200px] -mt-10 cursor-pointer logo-background-margin" src={logo} alt="sd" />
                    </Link>
                    {/* <img className="image-div w-[300px] h-[200px] -mt-10 cursor-pointer logo-background-margin" src={logo} alt="sd" /> */}
                </div>
                <div className="text-center mt-0 py-[50px] headline-div" id ="headline">
                    <h1 className="font-bold text-white text-center text-6xl fade">{lang === 'en' ? 'The road to your smart contract' : 'Der Weg zu Ihrem Smart Contract'}</h1>
                    <p className="mt-6 text-white text-lg font-semibold fade">{lang === 'en' ? 'Every new smart contract can be the beginning of something incredible.' : 'Jeder neue Smart Contract kann der Ursprung für etwas Unglaubliches sein. Lassen Sie uns Ihnen helfen das notwendige Fundament zu errichten.'}</p>
                </div>
            </div>
        </>
    )
}
