import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import styles from './info.module.css'

import Stack from '@mui/material/Stack';

import { Typography } from '@mui/material';

export default function Info({ lang, step, contract, environmental }) {

  console.log("Info", environmental)

  const MetaMaskText = {
    text: lang === 'en' ? "Contact us for an non-obligate first meeting and receive valuable information for your blockchain project and first initial steps." : "MetaMask ist ein Browser Addon, welches dem Nutzer ein privates Wallet zur Verfügung stellt. Dieses Wallet kann auf unterschiedlichen Webseiten genutzt werden um mit Blockchain Applikationen, wie zum Beispiel einem Smart Contract, zu interagieren."

  }
  const GoerliEtherText = {
    text: lang === 'en' ? "Smart Contracts are computer programs that live on the blockchain. In order to interact with the contracts, transactions need to be sent. A transaction is a way of sending information from one party to another. Note, that the sender has to pay Ethereum as transaction fees." : "Smart Contracts sind Computer Programme, welche auf einer Blockchain hinterlegt sind und selbstständig aufgrund Ihrer Programmierung funktioneren. Um einem Smart Contract neue Informationen oder Aufgaben zukommen zu lassen, werden sogenannte Transaktionen verwendet. Eine Transaktion spiegelt einen Informationstransfer zwischen zwei Parteien auf der Blockchain wider. Dabei ist zu beachten, dass eine Transaktion Kosten für den Sender verursacht. Diese Transaktionskosten werden mit Ether bezahlt.",
    text2: lang === 'en' ? "Because our smart contracts are located on the goerli test blockchain, you will first need to apply for some free Test-Ether. Click the following link to do so." : "Da unsere Test Smart Contracts auf dem Goerli Testnetzwerk zur Verfügung stehen müssen Sie sich Ether auf dem Goerli Testnetzwerk auf Ihr Wallet laden. Klicken Sie dazu auf folgenden Button und kopieren Sie Ihre Ethereum Adresse."
  }
  const WeightedDistributionContractText = {
    text: lang === 'en' ? "This smart contract allows you to create your own digital stock company. Set the total Supply, transfer shares to other users and distribute dividends in Goerli-Ether." : "Mit diesem Smart Contract können Sie ihre eigene Digitale Aktiengesellschaft gründen, die Anzahl der Aktien festlegen, diese an unterschiedliche Personen (Wallets) verteilen und an alle Aktienhalter eine Dividende in Goerli Ether ausschütten."
  }
  const VotingContractText = {
    text: lang === 'en' ? "Register voters with their Ethereum address, create a poll with time boundaries and wait for the results." : "Registrieren Sie Wahlberechtigte, erstellen Sie eine zeitlich begrenzte Abstimmung und warten Sie auf das Ergebnis."
  }
  const StreamingContractText = {
    text: lang === 'en' ? "Create a payment stream, which distributes the sent ETH over time to the determined address." : "Erstellen Sie einen Zahlungsstream, welcher über eine definierte Zeit die zum Streamen freigegebenen Ethereum an die entsprechende Addresse sendet."
  }
  const GeneralContractsText = {
    text: lang === 'en' ? "Please select the contract you want to interact with." : "Wählen Sie im Drop-Down-Menü den Smart Contract, mit welchem Sie interagieren möchten."
  }


  switch (step) {
    case 0:
      return (
        <div>
          <div className={styles.metamaskText}>
            {MetaMaskText.text}
          </div>
          <br></br>
          <ol>
            <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
              {lang === 'en' ? <li> Install Metamask Browser Extension <Link target="_blank" href="https://metamask.io/download/">here</Link></li> : <li> Laden Sie sich das MetaMask Addon <Link target="_blank" href="https://metamask.io/download/">hier</Link> herunter</li>}
              {/* {(environmental.metamask === true) ? <CheckIcon /> : <CloseIcon />} */}
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
              {lang === 'en' ? <li> Create your own wallet</li> : <li> Erstellen Sie ihr eigenes Wallet</li>}
              {/* {(environmental.metamask === true) ? <CheckIcon /> : <CloseIcon />} */}
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
              {lang === 'en' ? <li> Connect to the <Link target="_blank" href="https://blog.cryptostars.is/goerli-g%C3%B6rli-testnet-network-to-metamask-and-receiving-test-ethereum-in-less-than-2-min-de13e6fe5677">Goerli test network</Link></li> : <li> Wechseln Sie auf das <Link target="_blank" href="https://blog.cryptostars.is/goerli-g%C3%B6rli-testnet-network-to-metamask-and-receiving-test-ethereum-in-less-than-2-min-de13e6fe5677">Goerli Testnetzwerk</Link></li>}
              {/* {(environmental.goerli === true) ? <CheckIcon /> : <CloseIcon />} */}
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
              {lang === 'en' ? <li> Connect Metamask to this page</li> : <li> Verbinden Sie MetaMask mit dieser Seite</li>}
              {/* {(environmental.connected === true) ? <CheckIcon /> : <CloseIcon />} */}
            </Stack>
          </ol>
        </div >
      )
    case 1:
      return (
        <div>
          <Container>
            <Typography mb={4}>
              {GoerliEtherText.text}
            </Typography>
          </Container>
        </div >
      )
    case 2:
      switch (contract) {
        case 1:
          return (
            <div className='max-w-[870px] m-auto mb-[20px]'>
              {GeneralContractsText.text}
            </div>
          )
        case 2:
          return (
            <div className='max-w-[870px] m-auto mb-[20px]'>
              {lang === 'en' ? <span className={styles.infoheading}>Description: </span> : <span className={styles.infoheading}>Beschreibung: </span>}
              {VotingContractText.text}
            </div>
          )
        case 3:
          return (
            <div className={styles.contractText}>
              {lang === 'en' ? <span className={styles.infoheading}>Description: </span> : <span className={styles.infoheading}>Beschreibung: </span>}
              {WeightedDistributionContractText.text}
            </div>
          )
        case 4:
          return (
            <div className={styles.contractText}>
              {lang === 'en' ? <span className={styles.infoheading}>Description: </span> : <span className={styles.infoheading}>Beschreibung: </span>}
              {StreamingContractText.text}
            </div>
          )
        default:
          break;
      }
    default:
      break;
  }
}
