import React from 'react'

export default function LanguageToggle({ toggleLanguage, lang }) {
    return (
        // <div onClick={toggleLanguage} className="fixed cursor-pointer top-10 text-black font-bold text-3xl right-10">
        <div onClick={toggleLanguage} className="flex justify-center fixed top-[20px] right-8">
            <button className="bg-sky-200 px-6 font-bold text-2xl rounded">{lang}</button>
            {/* <div class="form-check form-switch">
                <input class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-gray-200 bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" id="flexSwitchCheckChecked" checkedb />
                <label class="form-check-label inline-block text-black font-bold text-2xl" for="flexSwitchCheckChecked">{lang}</label>
            </div> */}
        </div>

        // </div>
    )
}
