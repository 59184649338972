import React from 'react'
import axios from 'axios'

export default function ContactUs({ lang }) {
    const [name, setName] = React.useState("");
    const [reason, setReason] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [content, setContent] = React.useState("");

    /*
    Funktion um die Contact Requests an das PHP Backend weiterzuleiten
    Datei findet sich im Ordner php
    PHP Seite sendet eine email an die vorgegebene Email Addresse und
    inkludiert auf Basis der eingetragenden Felder die Informationen in
    die Email
    */
    const handleSubmit = (evt) => {
      evt.preventDefault();

      const message = {
        name: name,
        reason: reason,
        email: email,
        message: content,
      }

      console.log(message)

      axios({
        method: "POST",
        url:"https://newblocks.eu/php/",
        data:  message
      }).then((response)=>{
        if (response.data.status === 'success') {
          alert("Message Sent.");
        } else if(response.data.status === 'fail') {
          console.log(response.data)
        }
      })
    }


    return (
        <div id="contact-us" className="container-div text-center mt-8">
            {/* <h1 className="mb-8 text-4xl text-blue-400 font-bold"> {lang === 'en' ? 'Contact Us' : 'Kontaktieren Sie Uns'} </h1> */}
            <h1 className="mb-8 text-3xl font-semibold"> {lang === 'en' ? 'Contact Us' : 'Kontaktieren Sie uns...'} </h1>
            <form className="flex flex-col gap-y-3 form-container-div" onSubmit={handleSubmit}>
                <input className="border-2 rounded py-1 px-2" type="text" onChange={e => setName(e.target.value)} placeholder={`${lang === 'en' ? 'Name' : 'Name'}`} />
                <input className="border-2 rounded py-1 px-2" type="text" onChange={e => setReason(e.target.value)} placeholder={`${lang === 'en' ? 'Subject' : 'Betreff'}`} />
                <input className="border-2 rounded py-1 px-2" type="text" onChange={e => setEmail(e.target.value)} placeholder={`${lang === 'en' ? 'Email Address' : 'E-Mail-Adresse'}`} />
                <textarea className="border-2 rounded py-1 px-2" onChange={e => setContent(e.target.value)} name="message" id="text" cols="30" rows="10" placeholder={`${lang === 'en' ? 'Enter your message' : 'Hinterlassen Sie Ihre Nachricht'}`}></textarea>
                <button className="py-2 px-4 hover:bg-gray-600 cursor-pointer font-semibold text-xl rounded bg-blue-400 text-white w-[150px]" type="submit">{ lang === 'en' ? 'Submit': 'Abschicken'}</button>
            </form>
        </div>
    )
}
