import React, { useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom';
import pic from '../../assets/pic.jpg'

export default function Section2({ lang, contractHandler }) {
    const buttonClickHandlerVoting = () => {
        contractHandler(2)
    }
    const buttonClickHandlerDistribution = () => {
        contractHandler(3)
    }
    const buttonClickHandlerStreaming = () => {
        contractHandler(4)
    }
    // useEffect(() => {
    //     window.onscroll = handleScrolle
    // }, [])
    // const handleScrolle = (e) => {
    //     var position = section1Ref.current.getBoundingClientRect();
    //     if (position.top < window.innerHeight && position.bottom >= 0) {
    //         section1Ref.current.classList.add("fade");
    //     } else {
    //         section1Ref.current.classList.remove("fade");
    //     }
    // }
    // var section1Ref = useRef(null);
    return (
        <div id="section2" className="container-div mt-[50px]">
            <div className="text-center">
                <h1 className="font-bold text-4xl text-blue-400"> {lang === 'en' ? 'Interact with our smart contracts' : 'Testen Sie unsere Beispiel Smart Contracts'} </h1>
                <p className="text-2xl mt-4">  {lang === 'en' ? 'Connect with Metamask, claim some Ether on the Goerli Testnet and explorer our sample Smart Contracts.' : 'Verbinden Sie sich mit MetaMask, holen Sie sich kostenlose Ether für das Goerli Testnet und erkunden Sie anschließend die Smart Contracts.'} </p>
            </div>
            <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-x-10 text-center mt-[50px]">
                <div>
                    <div className="mt-8">
                        <h2 className="text-2xl font-bold"> {lang === 'en' ? 'Voting Contract' : 'Voting Contract'}</h2>
                        <p className="mt-3"> {lang === 'en' ? 'Register voters with their Ethereum address, create a poll with time boundaries and wait for the results' : 'Registrieren Sie Wahlberechtigte, erstellen Sie eine zeitlich begrenzte Abstimmung und warten Sie auf das Ergebnis.'} </p>
                        <button onClick={buttonClickHandlerVoting} className="mt-3 rounded-3xl hover:bg-gray-600 bg-blue-400 py-1 px-6 text-white font-semibold">
                            <NavLink to="/contractInteraction">{lang === 'en' ? 'Explore' : 'Erkunden'} </NavLink>
                        </button>
                    </div>
                    <div className="mt-8">
                        <h2 className="text-2xl font-bold"> {lang === 'en' ? 'Share/Dividend Contract' : 'Share/Dividend Contract'} </h2>
                        <p className="mt-3"> {lang === 'en' ? 'Create an object, distribute its shares and the contract handles payouts like dividends based on the shares' : 'Erstellen sie ein Asset, verteilen Sie die Shares und der Smart Contract kümmert sich um anteilige Auszahlungen wie beispielsweise einer Dividende.'} </p>
                        <button onClick={buttonClickHandlerDistribution} className="mt-3 rounded-3xl hover:bg-gray-600 bg-blue-400 py-1 px-6 text-white font-semibold">
                            <NavLink to="/contractInteraction">{lang === 'en' ? 'Explore' : 'Erkunden'} </NavLink>
                        </button>
                    </div>
                </div>
                <div>
                    <img className="md:block h-[400px] rounded-lg hidden" src={pic} alt="pic" />
                </div>
                <div>
                    {/* <div className="mt-8">
                        <h2 className="text-2xl font-bold"> {lang === 'en'? 'ENS Registration' : 'ENS Registrierung'} </h2>
                        <p className="mt-3"> {lang === 'de'? 'Reservieren Sie sich einen Domain NFT, der als Klarname für ihr Wallet fungiert.' : 'Link an Ethereum address to a domain name, like in the world wide web the DNS and IP addresses are linked.'}</p>
                        <button className="mt-3 hover:bg-gray-600 rounded-3xl bg-gray-400 py-1 px-6 text-white font-semibold"> {lang === 'en'? 'Coming soon' : 'In Entwicklung'} </button>
                    </div> */}
                    <div className="mt-8">
                        <h2 className="text-2xl font-bold"> {lang === 'en' ? 'Crowdfunding Platform' : 'Crowdfunding Plattform'} </h2>
                        <p className="mt-3"> {lang === 'de' ? 'Erstellen Sie eine Crowdfunding Kampagne auf einer neuen Plattform, bei der Sie Ideen mit Etherium fördern können' : 'Create a crowdfunding campagne on a new platform and fund new ideas with Etherium.'}</p>
                        <button onClick={ () =>
                            window.open(
                            'https://crowdfunding.newblocks.eu',
                            '_blank' // <- This is what makes it open in a new window.
                            )
                        } className="mt-3 rounded-3xl hover:bg-gray-600 bg-blue-400 py-1 px-6 text-white font-semibold">
                        {lang === 'en' ? 'Explore' : 'Erkunden'}
                        </button>
                    </div>
                    <div className="mt-8">
                        <h2 className="text-2xl font-bold"> {lang === 'en' ? 'Streaming Payments' : 'Streaming Payments'}</h2>
                        <p className="mt-3"> {lang === 'en' ? 'Start a payment stream, which automatically pays a defined amount of coins over a determined time period.' : 'Erstellen Sie einen Payment Stream, welcher automatisch eine definierte Menge eines Coins über eine von Ihnen bestimmte Zeit ausschüttet.'} </p>

                        <button onClick={buttonClickHandlerStreaming} className="mt-3 rounded-3xl hover:bg-gray-600 bg-blue-400 py-1 px-6 text-white font-semibold">
                            <NavLink to="/contractInteraction">{lang === 'en' ? 'Explore' : 'Erkunden'} </NavLink>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}
