import React from 'react'
import ContactUs from '../LandingPage/ContactUs'
import MultiStep from '../../components/MultiStep/MultiStep'

export default function Index({lang, contract}) {
  /*
  rendern der jeweiligen componenten
  lang und contract beinhalten die aktuelle sprache als auch den ausgewählten contract
  werden an die jeweiligen componenten weitergegeben
  */
  return (
    <div>
      <MultiStep lang={lang} contract={contract}/>
      <ContactUs lang={lang} />
    </div>
  )
}
