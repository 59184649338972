import React from 'react'

export default function Section1({ lang }) {

    function goToContactUs(){
        document.getElementById("contact-us").scrollIntoView({behavior: 'smooth' });
    }
    // function goToDevelopement(){
    //     document.getElementById("portfolio").scrollIntoView({block: 'start', behavior: 'smooth' });
    // }
    // function goToDetails(){
    //     document.getElementById("section2").scrollIntoView({block: 'start', behavior: 'smooth' });
    // }

    const card1 = {
        heading: lang === 'en' ? "First Contact" : "Erstkontakt",
        text: lang === 'en' ? "Contact us to arrange a First Contact Meeting where we talk about your project, provide the first valueable information and discuss follow up steps." : "Kontaktieren Sie uns für ein unverbindliches Erstgespräch und erhalten Sie wertvolle Informationen für Ihr Blockchain Projekt und die folgenden ersten Schritte."
    }
    const card2 = {
        heading: lang === 'en' ? "Technical Details" : "Technische Details",
        text: lang === 'en' ? "Defining the technical pillars of the project together. Choose a fitting blockchain, define the smart contract capabilities and contract interface.": "Lassen Sie uns gemeinsam die technischen Säulen des Projektes definieren. Über die Auswahl der besten Blockchain zum Design des Smart Contracts hin zu notwendigen Interaktionsmöglichkeiten wird das gesamte Spektrum abgedeckt."
    }
    const card3 = {
        heading: lang === 'en' ? "Developing" : "Entwicklung",
        text: lang === 'en' ? "Iterative process to develop and realize the defined smart contract. Going through the phases of prototyping, finalization and security audit.": "Das Smart Contract Projekt wird in einem iterativen Prozess realisiert. Dabei werden in enger Kundenabstimmung die Phasen Prototyping, Finalisierung und Security Audit durchlaufen."
    }
    return (
        <div id="section1" className="bg-sectino1 text-white md:h-[730px] lg:h-[620px] container-div">
            <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-8 md:flex md:flex-col py-[50px] relative">
                <div className={`bg-white animate-box transform transition-all duration-500 translate-y-0 md:absolute md:top-[200px] md:w-[30%] sm:w-full relative text-center text-black px-10 shadow-white hover:shadow-sm cursor-pointer shadow-sm py-12 rounded`}>
                    <h2 className="text-2xl font-bold mb-4">{card1.heading}</h2>
                    <p className='mb-4'>{card1.text}</p>
                    <div className="w-20 custom-btn-div bg-sky-200 hover:bg-sky-300 px-6 font-bold text-2xl rounded" onClick={goToContactUs}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div className={`bg-white animate-box md:absolute md:top-[110px] md:left-[35%] md:w-[30%] sm:w-full relative text-center text-black px-10 shadow-white hover:shadow-sm cursor-pointer shadow-sm py-12 rounded`}>
                    <h2 className="text-2xl font-bold mb-4">{card2.heading}</h2>
                    <p className='mb-4'>{card2.text}</p>
                    <div className="w-20 custom-btn-div bg-sky-200 hover:bg-sky-300 px-6 font-bold text-2xl rounded" onClick={goToContactUs}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div className={`bg-white animate-box md:absolute md:top-[50px]  md:left-[70%] md:w-[30%] sm:w-full relative text-center text-black px-10 shadow-white hover:shadow-sm cursor-pointer shadow-sm py-12 rounded`}>
                    <h2 className="text-2xl font-bold mb-4">{card3.heading}</h2>
                    <p className='mb-4'>{card3.text}</p>
                    <div className="w-20 custom-btn-div bg-sky-200 hover:bg-sky-300 px-6 font-bold text-2xl rounded" onClick={goToContactUs}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}
