import React, { Component } from "react";
import useState from "react";
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import detectEthereumProvider from '@metamask/detect-provider'


const Web3 = require("web3")


async function getTokenAmount(addr) {
    const provider = await detectEthereumProvider()

    if (provider) {
        const web3 = new Web3(provider)
        let tokenAmount = await web3.eth.getBalance(addr)
        return tokenAmount
    }

}


export default class UserCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userBalance: "..."
        }
        this.props = {
            lang: props.lang,
        }
    }

    render() {
        return <Grid container justifyContent="center">
            <Card sx={{
                width: "350px",
            }}>
                <Box sx={{ p: 2 }}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Box display="flex"
                                justifyContent="flex-start"
                                alignItems="flex-start">
                                <Avatar variant="rounded" src="avatar1.jpg" />
                                <Stack ml={1}>
                                    <Typography fontWeight={700}>{window.ethereum.selectedAddress.substring(0, 12)}...{window.ethereum.selectedAddress.substring(39)}</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Goerli testnet
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton>
                                <LogoutIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Grid container sx={{ paddingTop: "10px", bgcolor: 'background.default' }} >
                    <Grid item xs={9}>
                        <Box ml={1} display="flex"
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <Chip label="GOETH" variant="outlined"></Chip>
                        </Box>

                    </Grid>
                    <Grid item xs={3}>
                        <Typography>{this.state.userBalance !== undefined ? this.state.userBalance : "undefined"}</Typography>
                    </Grid>
                </Grid>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                >
                </Stack>
            </Card>
        </Grid >
    }
}