import React, { Component } from "react";
import MetaMaskAuth from "../../pages/ContractInteraction/walletConnect";
import ContractInterface from "../../pages/ContractInteraction/contractInterface";
import Info from "../../pages/ContractInteraction/info";
import styles from "./multistep.module.css"
import detectEthereumProvider from '@metamask/detect-provider'

import VideoEmbedding from "./VideoEmbedding";
import GetTestEther from "./GetTestEther";
import CustomNavbar from "./CustomNavbar";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

const HDWalletProvider = require('@truffle/hdwallet-provider')
const Web3 = require("web3")

/*
Delay funktion um in asynchronen Funktionen diverse Zeiten abzuwarten
*/
async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/*
Funktion um vom verbunden Wallet die aktuelle Tokenanzahl auszulesen
Bezieht sich auf Goerli Test Ether, Ergebnis wird verwendet, um
im Multistep den Schritt als abgeschlossen oder ausstehend zu markieren
*/
async function hasTokens(addr) {
  const provider = await detectEthereumProvider()

  if (provider) {
    const web3 = new Web3(provider)
    let result
    let tokenAmount = await web3.eth.getBalance(addr)

    if (web3.utils.fromWei(tokenAmount, "ether") >= 0.05) {
      console.log(web3.utils.fromWei(tokenAmount, "ether") + " ETH")
      result = true;
    }
    else {
      console.log("Not enough ETH")
      result = false;
    }

    console.log("has tokens result", result)

    return new Promise((resolve, reject) => {
      resolve(result);
    });
  }
  else {
    return new Promise((resolve, reject) => {
      resolve("no provider");
    });
  }
}

/*
Multi Step Componente als Klasse

Wird als Guide verwendet um den Nutzer durch die zu tätigenden Schritte zu führen, bis dieser mit den Smart Contracts interagieren kann
Erkennt automatisch den aktuellen Stand und springt zwischen diesen hin und her, falls gewissen Bedinungen erfüllt/nicht erfüllt sind
*/
export default class MultiStep extends Component {
  constructor(initVar) {
    /*
    constructor initalisiert notwendige variablen
    führt automatisch beim ersten laden die checkState() Funktion aus
    */
    super(initVar);
    this.state = {
      step: 0,
      contract: initVar.contract,
      metamask: false,
      goerli: false,
      connected: false,
    };
    this.props = {
      lang: initVar.lang
    }

    if (this.state.step === 0) {
      this.checkState()
    }
  }

  /*
  Funktion zum überprüfen, ob die initial notwendigen Schritte für state 1 durchgeführt worden sind
    -> goerli testnet ausgewählt? (Ropsten Testnet hat die networkVersion 3, Goerli hat 5)
    -> gibt es eine gültige Ethereum Addresse
  */
  checkDetails() {
    if (window.ethereum !== undefined) {
      if (window.ethereum.networkVersion === 5) {
        this.setState({ goerli: true })
      }
      else {
        this.setState({ goerli: false })
      }

      if (window.ethereum.selectedAddress !== undefined && window.ethereum.selectedAddress !== [] && window.ethereum.selectedAddress !== null) {
        this.setState({ connected: true })
      }
      else {
        this.setState({ connected: false })
      }
    }
    else {
      this.setState({ goerli: false })
      this.setState({ connected: false })
    }
  }

  /*
  Funktion zum überprüfen, ob MetaMask installiert wurde
    -> wenn installiert ist window.ethereum vorhanden
    -> anschließend wird der detailcheck durchgeführt
  */
  checkEnvironment() {
    //Status components for user information
    if (window.ethereum) {
      this.setState({ metamask: true })
      this.checkDetails()
    }
    else {
      this.setState({ metamask: false })
      this.checkDetails()
    }
  }

  /*
  Funktion zum überprüfen des aktuell richtigen States:
    state 0: MetaMask + Wallet + Verbunden + Goerli Netzwerk
    state 1: Goerli test ether
    state 2: Smart Contract interaktionen
  */
  async checkState() {
    await delay(500) //notwendig um bei manuelle refresh das laden des window.ethereum objekts abzuwarten
    if (window.ethereum !== undefined) {
      // Ropsten networkVersion = 3
      // Goerli networkVersion = 5
      console.log("first check", parseInt(window.ethereum.networkVersion) === 5, window.ethereum.networkVersion, (window.ethereum.selectedAddress !== undefined && window.ethereum.selectedAddress !== null), window.ethereum.selectedAddress)
      if (parseInt(window.ethereum.networkVersion) === 5 && (window.ethereum.selectedAddress !== undefined && window.ethereum.selectedAddress !== null)) { //Wenn aktuelles MetaMask Netzwerk Goerli (5) und legitime Addresse dann weiter fortfahren
        hasTokens(window.ethereum.selectedAddress).then(response => {
          if (response) {
            this.setState({ step: 2 })
          }
          else {
            this.setState({ step: 1 })
          }
        })
      }
      else { //sonst auf step 0 blockieren
        if (this.state.step !== 0) {
          this.setState({ step: 0 })
        }
      }
    }
    else { //sonst auf step 0 blockieren
      if (this.state.step !== 0) {
        this.setState({ step: 0 })
      }
    }
  }

  /*
  ComponentDidMount wird automatisch ausgeführt, wenn die gerenderten Objekte fertig geladen sind
  Dabei werden entsprechende Event Listener aufgesetzt um bei Änderungen seitens des Users entsprechend reagieren zu können
   -> account changed wenn der Nutzer sein Wallet wechselt
   -> chainChanged wenn der Nutzer das verwendete Blockchain Netzwerk wechselt
  anschließend werden environment und state check druchgeführt

  Zusätzlich werden zwei intervalle gestartet.
  Einer wenn MetaMask nicht installiert ist (window.ethereum nicht vorhanden)
    -> Checkt alle 5 sek ob MetaMask installiert wurde
  Zweiter timer, wenn MetaMask installiert wurde
    -> Führt einen stateCheck alle 5 Sekunden durch, bis step 2 erreicht wird
  */
  componentDidMount() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (data) => {
        window.ethereum.selectedAddress = data[0]
        this.checkEnvironment()
        this.checkState()
      })
      window.ethereum.on("chainChanged", (chainId) => {
        window.ethereum.networkVersion = parseInt(chainId)
        this.checkEnvironment()
        this.checkState()
      })
      let timerId = setInterval(() => {
        if (this.state.step === 1) {
          this.checkState()
          if (this.state.step !== 1) {
            clearInterval(timerId);
          }
        }
      }, 5000);
    }
    else {
      let timerId = setInterval(() => {
        if (window.ethereum !== undefined) {
          clearInterval(timerId);
          this.setState({ metamask: true })
          window.location.reload()
        }
      }, 5000);
    }

    if (this.state.step === 0) {
      this.checkEnvironment()
    }
  }

  /*
  componentWillUnmount sollte verwendet werden, wenn man components aus dem aktuellen Screen UI entfernt
  Hierbei sollten entsprechende Eventlistener entfernt werden
  In diesem Fall kein Bedarf für die Funktion
  */
  componentWillUnmount() {

  }

  /*
  Rendering des aktuellen Interfaces, je nachdem welcher Step gerade aktiv ist
  */
  render() {
    switch (this.state.step) {
      case 0:
        return (
          <div className="">
            <CustomNavbar step={0} lang={this.props.lang} />
            <div className={styles.connectToMetamaskContainer}>
              <Info
                lang={this.props.lang}
                step={0}
                contract={0}
                environmental={this.state}
              />
              <br></br>
              <MetaMaskAuth
                lang={this.props.lang}
              />
              <VideoEmbedding state={this.state.step} contract={undefined} lang={this.props.lang} />

            </div>
          </div>
        );

      case 1:
        return (
          <div className="">
            <CustomNavbar step={1} lang={this.props.lang} />
            <div className={styles.getEtherContainer}>
              <Info
                lang={this.props.lang}
                step={1}
                contract={0}
                environmental={this.state}
              />
              <GetTestEther lang={this.props.lang} />
              <Box pb={2}>
                <Divider />
              </Box>
              {/* <VideoEmbedding state={this.state.step} contract={undefined} lang={this.props.lang} /> */}
            </div>
          </div>
        );

      case 2:
        return (
          <div className="">
            <CustomNavbar step={2} lang={this.props.lang} />
            <div className={styles.contractInterface}>
              <ContractInterface lang={this.props.lang} contract={this.state.contract} />
            </div>

            {/* <Contracts lang={this.props.lang} /> */}
          </div>
        );

      default:
        break;
    }
  }
}
