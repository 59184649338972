import "./App.css";
import { useState, useEffect, useLayoutEffect } from "react";
import LandingPage from "./pages/LandingPage";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LanguageToggle from "./components/LanguageToggle/LanguageToggle";
import { Routes, Route, useLocation } from "react-router-dom";
import ContractInteraction from "./pages/ContractInteraction";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CookieConsent from "./components/BasicComponents/CookieConsent";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

/*
Scrollt automatisch auf top der Seite, wenn ein Reload stattfindet
*/
const ScrollToTopWrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

/*
Initiale App Funktion
Relevante Informationen werden gespeichert
Die ausgewählte Sprache
Der ausgewählte Contract, standardisiert auf contract 1
*/
function App() {
  const location = useLocation();
  const [lang, setLang] = useState("de");

  const [contract, setContract] = useState(1)

  const setContractData = (data) => {
    setContract(data)
  }

  const toggleLanguage = () => {
    const toggle = lang === "en" ? "de" : "en";
    setLang(toggle);
    (lang === "de") ? document.getElementById("headline").style.margin = "auto" : document.getElementById("headline").style.margin = "0 0 0 6%";
  };

  /*
  Animationen für fade in fade auto wenn gewisse punkte der seite erreicht werden
  */
  useEffect(() => {
    window.onscroll = handleScroll;
  });

  const handleScroll = (e) => {
    if (window.location.pathname === '/') {
      console.log(location.pathname);
      var section2 = document.getElementById("section2");
      var portfolio = document.getElementById("portfolio");
      var contactUs = document.getElementById("contact-us");
      var section2Position = section2.getBoundingClientRect();
      var portfolioPosition = portfolio.getBoundingClientRect();
      var contactPosition = contactUs.getBoundingClientRect();
      if (
        section2Position.top < window.innerHeight &&
        section2Position.bottom >= 0
      ) {
        section2.classList.add("fade");
      }
      if (
        portfolioPosition.top < window.innerHeight &&
        portfolioPosition.bottom >= 0
      ) {
        portfolio.classList.add("fade");
      }
      if (
        contactPosition.top < window.innerHeight &&
        contactPosition.bottom >= 0
      ) {
        contactUs.classList.add("fade");
      }
    }
  };

  // route exception for goerli faucet api call
  const api_regex = /^\/sendtx\.*/
  // if using "/api/" in the pathname, don't use React Router
  if (api_regex.test(window.location.pathname)) {
    return <div /> // must return at least an empty div
  } else {

    /*
    Rendering der Webseite
    */
    return (
      <ThemeProvider theme={darkTheme}>
        <div className="App">
          <Header lang={lang} />
          <ScrollToTopWrapper>
            <Routes>
              {/*
            abhängig von der route werden unterschiedliche elemente der Webseite gerendert
            Route sucht nach jeweiliger index datei im entsprechenden ordner
  */}\
              <Route path="/" element={<LandingPage lang={lang} contractHandler={setContractData} />} />
              <Route path="/contractInteraction" element={<ContractInteraction lang={lang} contract={contract} />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy lang={lang} />} />
            </Routes>
          </ScrollToTopWrapper>
          <Footer lang={lang} />
          <CookieConsent />
          <LanguageToggle toggleLanguage={toggleLanguage} lang={lang} />
        </div>
      </ThemeProvider>
    );
  }
}
export default App;
