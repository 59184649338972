import React from 'react'
import ContactUs from './ContactUs'
import Portfolio from './Portfolio'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'

/*
Rendering der Route: '/' -> entspricht der Landingpage
Einzelne React Componenten werden in die Seite geladen
Componenten sind selbsterlärend, sind einfache Aufbau + Styling
*/
export default function Index({lang, contractHandler}) {
  return (
    <div>
        <Section1 lang={lang} />
        <Section2 lang={lang} contractHandler={contractHandler}/>
        <Section3 lang={lang} contractHandler={contractHandler}/>
        <Portfolio lang={lang} />
        <ContactUs lang={lang} />
    </div>
  )
}
