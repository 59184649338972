import * as React from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

export default function SplitText(props) {
  const text = props.info.text;
  const note = props.info.note;
  // const textLines = text.split(' ')

  return (
    <Box>
            {text ? <Container><Typography sx={{ lineHeight: 1.5 }} m={1} dangerouslySetInnerHTML={ {__html:text}}></Typography> </Container>: ""}
      {note ? (
        <Box p={2}>
        <Alert 
          sx={{
            width: "fit-content",
          }}
          severity="info"
        >
          <Typography dangerouslySetInnerHTML={ {__html:note}}></Typography>
        </Alert>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}
SplitText.propTypes = {
  info: PropTypes.object.isRequired,
};

// export default function ContractHelpDialog(props) {
//   const [open, setOpen] = React.useState(false);
//   const {contract,tab} = props;

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   console.log(tab)
//   console.log(contract)
//   return (
//     <div>
//         <Tooltip title="Need help?">
//             <IconButton tooltip="Need help?" onClick={handleClickOpen}>
//                 <HelpOutlineIcon tooltip="Need help?"  sx={{ fontSize: 30 }}/>
//             </IconButton>
//         </Tooltip>
//         <SimpleDialog
//             contract={contract}
//             tab={tab}
//             open={open}
//             onClose={handleClose}
//         />
//     </div>
//   );
// }
