import React, { useState, useRef } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import ContractHelpDialog from '../../components/BasicComponents/HelpModal'
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Table from '@mui/material/Table';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tab from '@mui/material/Tab';
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress';
import detectEthereumProvider from '@metamask/detect-provider'

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { isBefore, isAfter, getUnixTime } from "date-fns"

require('react-dom');

/*
Voting Contract Address am Ropsten Testnet
*/
const votingContractAddr = '0x50BF4bEc5dCB113A34020E28f1C81517948d92e0'
/*
Voting Contract Address am Goerli Testnet
*/
const votingContractGoerli = '0x1234dD6B319b02c8440dC90afeB00984238050fd'


/*
Beispiel Addressen um den Nutzer zu unterstützen
Beispielsweise neue Nutzer für ein Vote anzumelden
Jedoch nicht im Interface implementiert
Derzeit haben diese keine Funktion
*/
const exampleAccs = [
  '0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2',
  '0x4B20993Bc481177ec7E8f571ceCaE8A9e22C02db',
  '0x78731D3Ca6b7E34aC0F824c42a7cC18A495cabaB',
  '0x617F2E2fD72FD9D5503197092aC168c91465E7f2',
]


//Definieren der contract variable, dass diese Exisitiert
let contract

/*
initiale Rendervariable;
Dient als Dummy Daten, wenn der Nutzer noch keine Interaktion mit
dem Smart Contract gehabt hat
*/
let renderData = [{
  name: "You are not registered for a poll",
  question: "Can you see this sample poll?",
  id: "",
  objectOwner: "",
  start: "",
  end: "",
  amountYes: 10,
  amountNo: 5,
  voterCount: 15,
  contractOwner: "",
}]

//Definieren der provider variable
let provider

//Definieren der activen Contract Id
let activeContractId

/*
Definieren des retrunData Objectes
Unterscheidung zwischen
  own (Voting selbst erstellt)
  particiapte (Teilnehmer bei einem Vote)
Wird unterschieden, damit nur die möglichen Funktionen im Interface dargestellt werden
*/
let returnData = {
  own: new Array(),
  participate: new Array()
}

const Web3 = require('web3')
const HDWalletProvider = require('@truffle/hdwallet-provider')
const MyContract = require('../../build/contracts/Voting.json')

async function initializeSmartContract() {
  /*
  Ethereum Provider finden
  Funktion schaut, ob MetaMask vorhanden ist
  Wird als Schnittstelle für Blockchain Queries verwendet
  */
  provider = await detectEthereumProvider()

  //Wenn provider Vorhadnen
  if (provider) {
    //Wenn provider vorhanden
    const web3 = new Web3(provider)

    console.log('Loading contracts from adress...')
    //contract Informationen laden
    contract = new web3.eth.Contract(
      MyContract.abi,
      votingContractGoerli,
    )
    console.log('Contracts ready!\n')

    return contract

  } else {
    console.log('Please install MetaMask!')

    return undefined
  }
}

/*
Delay Funktion um in async Codestellen zu Warten
*/
async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/*
Linear Progress wird verwendet um den Voting Progress
zwischen Ja und Nein Votes darzustellen
*/
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: "70%", margin: "auto" }}>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">yes</Typography>
      </Box>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${100 - (Math.round(
          props.value,
        ))}%`}</Typography>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">no</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

// VOTING CONTRACT FUNCTIONS

/*
Alles Funktionen um mit dem Smart Contract zu interagieren
Bestehen aus diversen Error Checks und entwender aus einem:
  -> .send(on chain transaktion)
  -> .call(daten von chain auslesen)

.call besitzen return wert
.send kein return wert, lösen aber ein reloading aus
*/

async function userCreatePoll(name, question, start, end) {
  if (isBefore(start, end) && isAfter(end, start)) {
    await contract.methods
      .newPoll(name, question, start, end)
      .send({ from: window.ethereum.selectedAddress })
    return true
  }
  else {
    return "start time is after endtime"
  }
}

async function userRegisterNewVoter(pollId, addr) {
  console.log("registering to Poll Number:" + pollId);
  await contract.methods
    .registerVoter(pollId, addr)
    .send({ from: window.ethereum.selectedAddress })
}

async function userUnregisterNewVoter(pollId, addr) {
  console.log("unregistering to Poll Number:" + pollId);
  await contract.methods
    .unregisterVoter(pollId, addr)
    .send({ from: window.ethereum.selectedAddress })
}

async function userVotePoll(pollId, answer) {
  console.log(answer)
  var isTrueSet = (answer === 'true')
  await contract.methods
    .votePoll(pollId, isTrueSet)
    .send({ from: window.ethereum.selectedAddress })
}

async function userGetPollData(pollId) {
  const data = await contract.methods
    .polls(pollId)
    .call({ from: window.ethereum.selectedAddress })
  data.id = pollId
  return data
}

async function userGetBlockTime() {
  const time = await contract.methods
    .blockTime()
    .call({ from: window.ethereum.selectedAddress })
  return time
}

async function userGetContractOwner() {
  const owner = await contract.methods
    .contractOwner()
    .call({ from: window.ethereum.selectedAddress })
  return owner
}

async function userGetPollOwner() {
  const owner = await contract.methods
    .owner()
    .call({ from: window.ethereum.selectedAddress })
  return owner
}

async function userGetPollCount() {
  const count = await contract.methods
    .pollCount()
    .call({ from: window.ethereum.selectedAddress })
  return count
}

async function userGetVoterCount(pollId) {
  const count = await contract.methods
    .getVoterCount(pollId)
    .call({ from: window.ethereum.selectedAddress })
  return count
}

async function getAllPollsFromHolder(addr) {
  const pollsFromHolder = await contract.methods
    .allPollsFromHolder(addr)
    .call({ from: window.ethereum.selectedAddress })
  return pollsFromHolder
}

async function allPollsHolderParticipates(addr) {
  const pollsFromHolder = await contract.methods
    .allPollsHolderParticipates(addr)
    .call({ from: window.ethereum.selectedAddress })
  return pollsFromHolder
}

/*
Lädet Informaitonen auf grund der vorgegeben pollId
und fügt es zu own oder participate des returnData Objectes hinzu
*/
async function getStructuredObject(pollId, type) {

  let dataObject = await userGetPollData(pollId)
  let contractOwner = await userGetContractOwner()


  let structuredObject = {
    name: dataObject.name,
    question: dataObject.question,
    id: parseFloat(dataObject.id),
    objectOwner: dataObject.creator,
    start: new Date(dataObject.start * 1000).toISOString().slice(0, 19).replace('T', ' '),
    end: new Date(dataObject.end * 1000).toISOString().slice(0, 19).replace('T', ' '),
    amountYes: dataObject.yes,
    amountNo: dataObject.no,
    voterCount: dataObject.voterCount,
    contractOwner: contractOwner,
  }

  returnData[type].push(structuredObject)
}

/*
Fragt alle ids von votes an welchen der Nutzer Teilnimmt
Speichert diese als Array in:
  shareIdsOwn
  shareIdsParticipate
Mittels Loop werden alle notwendigen Informationen durch die Funktion
getStructuredObject(..) über die Blockchain gequeried

Solange die Daten asynchron geladen werden, wartet die Funktion
Nachdem alle Daten geladen wurden (Länge von ids und returnData Objekt stimmen überein)
werdne die geladenen Daten zurückgegeben
*/
async function getPollDataFromAddr(addr) {
  let shareIdsOwn = await getAllPollsFromHolder(addr)
  let shareIdsParticipate = await allPollsHolderParticipates(addr)

  for (let key in shareIdsOwn) {
    getStructuredObject(shareIdsOwn[key], "own")
  }

  for (let key in shareIdsParticipate) {
    getStructuredObject(shareIdsParticipate[key], "participate")
  }

  console.log("return Data", returnData, "own", shareIdsOwn, "part", shareIdsParticipate)

  while (returnData.own.length !== shareIdsOwn.length ||
    returnData.participate.length !== shareIdsParticipate.length) {
    await delay(200)
  }

  return returnData
}

/*
Ermittelet die akutelle Id der jeweils aktiven Share
unterscheidet beim Ermitteln, ob basierend auf der Obj ID gesucht wird oder nicht
*/
function activeObjectLength(activeObj, byObjId, pollDataInfo) {
  if (byObjId === true) {
    for (let key in pollDataInfo) {
      if (pollDataInfo[key].id === activeObj.id) {
        return key
      }
    }
    return pollDataInfo.length
  }
  else {
    for (let key in pollDataInfo) {
      if (JSON.stringify(pollDataInfo[key]) === JSON.stringify(activeObj)) {
        return key
      }
    }
    return 0
  }
}

/*
Bubble Sort Funktion, um die asynchron geladnenen Daten von returnObjekt
aufgrund der Id in eine aufsteigende Reihenfolge zu übeführen
*/
function sortArray(array) {
  let swapped = true;
  while (swapped) {
    swapped = false;
    for (let j = 0; j < array.length - 1; j++) {
      if (array[j].id > array[j + 1].id) {
        let temp = array[j];
        array[j] = array[j + 1];
        array[j + 1] = temp;
        swapped = true;
      }
    }
  }
  return array;
}

/*
neu geladene Daten werden in Struktur gebracht und
in der renderData Variable gespeichert

*Hint: Eventuell falsch implementiert und könnte mittels einer State Variable mit Set Funktion ersetzt werden
       Eine der Kinderkrankheiten der ersten React Schritte
*/
function setData(data) {
  renderData = sortArray(data)
}

// TAB-PANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <h1>{children}</h1>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/*
React Componente VotingInterface
beinhaltet alle notwenidgen Logikschritte, um das Interface entsprechend aufzubauen
basierend auf stateVariablen und useEffect hooks wird das ordentliche Laden sichergestellt
*/
export default function VotingInterface({ lang }) {
  //variablen welche unterschiedliche states für das FrontEnd darstellen
  const [progressBarValue, setProgressBarValue] = useState(0) //progress bar standard value
  const [isLoading, setLoading] = useState(true); //contract daten werden geladen - bzw. sind geladen
  const [isProcessing, setProcessing] = useState(false); //daten werden verarbeitet (smart contract call)
  const [init, setInit] = useState(false) //initales daten laden abgeschlossen
  const [newContract, setNewContract] = useState(true); //contract daten wurden geupdated
  const [changedOwnOrParticipate, setChangedOwnOrParticipate] = useState(false) //switch von own in participate zustand
  const [pollData, setPollData] = useState("") //initale poll data
  const [tabValue, setTabValue] = React.useState(0); //aktuell zu rendernder tab
  const [activeObj, setActiveObj] = useState(renderData[0]) //speichern des aktiven Objektes (ausgewählte poll daten)
  const [oldObj, setOldObj] = useState(renderData[0]) //speichern des vorherigen poll Objektes
  const [newData, setNewData] = useState(false) //neu geladene daten
  const [renderType, setRenderType] = useState(false) //false -> initial und bei neuer share | true -> bei call einer share
  const [ownOrParticipate, setOwnOrParticipate] = useState("participate") //ob own oder participate angezeigt werden soll

  // InputValidation Statevars
  const [inputQuestionValid, setInputQuestionValid] = useState(false);
  const [inputDateValid, setInputDateValid] = useState(true);
  const [inputNameValid, setInputNameValid] = useState(false);
  const [regHolderEnabled, setRegHolderEnabled] = useState(false);
  const [unregHolderEnabled, setUnregHolderEnabled] = useState(false);

  //input field messages
  const [inputErrorRegAddressEn, setInputErrorRegAddressEn] = useState('(e.g. "0x1f07208802d...")');
  const [inputErrorRegAddressDe, setInputErrorRegAddressDe] = useState('(z.B. "0x1f07208802d...")');
  const [inputErrorUnregAddressDe, setInputErrorUnregAddressDe] = useState('');
  const [inputErrorUnregAddressEn, setInputErrorUnregAddressEn] = useState('');
  const [startTimeError, setStartTimeError] = useState('');


  const [radioValue, setRadioValue] = useState('')
  const [startTime, setStartTime] = useState(Date.now())
  const [endTime, setEndTime] = useState(Date.now() + 10800000)

  /*
  states updaten, wenn der Tab gewechselt wird
  -> own wenn tab value 1 ist
  -> particiapte wenn tab value 0 oder 2 ist
  */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setInputQuestionValid(false);
    setInputNameValid(false);
    setInputDateValid(true);
    setRegHolderEnabled(false);
    setUnregHolderEnabled(false);
    setRadioValue("");
    setInputErrorRegAddressEn('(e.g. "0x1f07208802d...")');
    setInputErrorRegAddressDe('(z.B. "0x1f07208802d...")');
    setInputErrorUnregAddressEn('');
    setInputErrorUnregAddressDe('');

    if (newValue === 1) {
      setOwnOrParticipate("own");
      setChangedOwnOrParticipate(true)
      updateDocumentData(pollData.own[0], false, pollData.own)
    }
    else if (newValue === 0 || newValue === 2) {
      setOwnOrParticipate("participate");
      setChangedOwnOrParticipate(true)
      updateDocumentData(pollData.participate[0], false, pollData.participate)
    }
    setStartTime(Date.now())
    setEndTime((Date.now() + 10800000))
    setStartTimeError('')
  };

  //updaten wenn radio button wert geändert wird
  const handleRadioChange = (event, newValue) => {
    setRadioValue(newValue)
  };

  //Handle Input Change Events
  const handleNameInputChange = (event) => {
    const { target: { value } } = event;
    if (!value) {
      setInputNameValid(false);
    }
    else {
      setInputNameValid(true);
    }
  }

  //Handle Question Input Change
  const handleQuestionInputChange = (event) => {
    const { target: { value } } = event;
    if (!value) {
      setInputQuestionValid(false);
    }
    else {
      setInputQuestionValid(true);
    }
  }

  //Handle DateTime Input Change
  function handleStartDateInputChange(newValue) {
    console.log(newValue)
    var logDate = new Date(endTime)
    console.log(logDate)
    if (newValue.getTime() >= endTime) {
      setInputDateValid(false);
      lang === 'en' ? setStartTimeError("start must be before end") : setStartTimeError("Startzeit muss vor Endzeit sein")
    }
    else {
      setInputDateValid(true);
      setStartTimeError("")
    }
  }

  //Handle DateTime Input Change
  function handleEndDateInputChange(newValue) {
    console.log(newValue)
    console.log("startTime:")
    var logDate = new Date(startTime)
    console.log(logDate)
    if (newValue.getTime() <= startTime) {
      setInputDateValid(false);
      lang === 'en' ? setStartTimeError("end must be after start") : setStartTimeError("Endzeit muss nach Startzeit sein")
    }
    else {
      setInputDateValid(true);
      setStartTimeError("")
    }
  }

  //Handle Address Input Change
  const handleRegAddressInputChange = (event) => {
    const { target: { value } } = event;
    setInputErrorRegAddressEn("");
    setInputErrorRegAddressDe("");
    let reg = new RegExp(/^0x[a-fA-F0-9]{40}$/).test(value);
    if (!reg) {
      setInputErrorRegAddressEn('Enter valid address (e.g. "0x1f07208802d...")');
      setInputErrorRegAddressDe('Adresse eingeben (z.B. "0x1f07208802d...")');
      setRegHolderEnabled(false);
    }
    else {
      setInputErrorRegAddressEn("");
      setInputErrorRegAddressDe("");
      setRegHolderEnabled(true);
    }
  }

  //Handle Address Input Change
  const handleUnregAddressInputChange = (event) => {
    const { target: { value } } = event;
    setInputErrorUnregAddressDe("");
    setInputErrorUnregAddressEn("");
    let reg = new RegExp(/^0x[a-fA-F0-9]{40}$/).test(value);
    if (!reg) {
      setInputErrorUnregAddressEn('Enter valid address (e.g. "0x1f07208802d...")');
      setInputErrorUnregAddressDe('Adresse eingeben (z.B. "0x1f07208802d...")');
      setUnregHolderEnabled(false);
    }
    else {
      setInputErrorUnregAddressDe("");
      setInputErrorUnregAddressEn("");
      setUnregHolderEnabled(true);
    }
  }


  // Voting Refs
  //
  const vCreatePollName = useRef('')
  const vCreatePollQuestion = useRef('')
  const vRegisterUserAddress = useRef('')
  const vUnregisterUserAddress = useRef('')
  const vVotePollNumber = useRef('')
  const vVotePollAnswer = useRef('')
  const vShowPollNumber = useRef('')


  /*
  DOM Werte updaten:
      -> wenn ein update durch den Smart Contract stattfindet
      -> wenn tabswitch zwischen own und participate bereich stattfindet
      -> wenn ausgewähltes object (eigenes Voting) nicht dem vorherigen object entspricht; bedeutet actives object wurde geupdated
  */
  function updateDocumentData(obj, byNewUpdate, activeObj = undefined, pollDataInfo) {
    obj = (byNewUpdate === true) ? renderData[activeObjectLength(activeObj, true, pollDataInfo)] : obj
    console.log("updating document data")
    activeContractId = obj.id

    document.getElementById(
      'name',
    ).innerHTML = `${obj.name}`

    document.getElementById(
      'question',
    ).innerHTML = `${obj.question}`

    document.getElementById(
      'id',
    ).innerHTML = `${obj.id}`

    document.getElementById(
      'pollCreator',
    ).innerHTML = `${obj.objectOwner}`

    document.getElementById(
      'pollStartTime',
    ).innerHTML = `${obj.start}`

    document.getElementById(
      'pollEndTime',
    ).innerHTML = `${obj.end}`

    document.getElementById(
      'amountYes',
    ).innerHTML = `${obj.amountYes}`

    document.getElementById(
      'amountNo',
    ).innerHTML = `${obj.amountNo}`

    document.getElementById(
      'voterCount',
    ).innerHTML = `${obj.voterCount}`

    document.getElementById(
      'contractOwner',
    ).innerHTML = `${obj.contractOwner}`

    //handle no votes (zero division)
    var percentYes;
    if (parseInt(obj.amountYes) + parseInt(obj.amountNo) <= 0) {
      percentYes = 50;
    }
    else {
      percentYes = (parseInt(obj.amountYes) / (parseInt(obj.amountYes) + parseInt(obj.amountNo))) * 100;
    }

    setProgressBarValue(percentYes)
    setActiveObj(obj);
  }

  /*
  Wenn newContract == true wird der contract zum ersten mal initial geladen
  bedeutet Objektinformationen komplett abgreifen, wenn vorhanden diese anzeigen ansonsten das sampleObject verwerden
  State Varialben entsprechend setzen
  */
  if (newContract === true) {
    initializeSmartContract()
      .then(contr => {
        contract = contr
        console.log("contract", contract)
        getPollDataFromAddr(window.ethereum.selectedAddress).then(data => {
          returnData = {
            own: new Array(),
            participate: new Array()
          }

          console.log("received data", data)
          setPollData(data)


          if (data[ownOrParticipate].length !== 0) {
            setData(data[ownOrParticipate]);
            if (newContract === true && init === true) {
              console.log("new contract")
              console.log(data, ownOrParticipate)
              setActiveObj(data[ownOrParticipate][data[ownOrParticipate].length - 1])
              setNewContract(false)
            }
          }
          else {
            let sampleData = [{
              name: "You are not registered for a poll",
              question: "Can you see this sample poll?",
              id: "",
              objectOwner: "",
              start: "",
              end: "",
              amountYes: 10,
              amountNo: 5,
              voterCount: 15,
              contractOwner: "",
            }]

            setData(sampleData);

            if (newContract === true && init === true) {
              setActiveObj(sampleData[0])
              setNewContract(false)
            }
          }

          setNewContract(false)
          setInit(true)
          setLoading(false);
          setProcessing(false)
        })
      })
  }

  /*
  Wenn die Daten im Contract geupdated werden, ist newData true
  Dabei müssen die neuen Daten geladen werden; neuer Call an smart contract
  Daten müssen dann entsprechend in DOM eingebettet werden
  State Variablen für weiteres rendern richtig setzen
  */
  if (newData === true) {
    getPollDataFromAddr(window.ethereum.selectedAddress).then(data => {
      returnData = {
        own: new Array(),
        participate: new Array()
      }
      setPollData(data)

      if (data[ownOrParticipate].length !== 0) {
        setData(data[ownOrParticipate]);
        updateDocumentData(activeObj, true, activeObj, pollData[ownOrParticipate])
        setProcessing(false)
      }
    })
    setNewData(false)
  }

  /*
  Effect der bei neuem rendern der Seite vergleicht, ob sich das activeObject geändert hat
  Bei Änderung wird DOM elemente geupdated, und das oldObject mit activeObject gleichgesetzt
  */
  React.useEffect(() => {
    if (JSON.stringify(oldObj) !== JSON.stringify(activeObj)) {
      updateDocumentData(activeObj, false, pollData[ownOrParticipate])
      setOldObj(activeObj)
    }
  })

  /*
  Rendering des DOM;
  isLoading -> während daten geladen werden
  ownOrParticipate -> führt im FrontEnd dazu, dass im Autocomplete nur die tatsälich möglichen Votes
                      für die jeweilgen Operationen angezeigt werden

  wird per if Funktion festgelegt, da sonst kein update durch ein stateupdate durchgeführt wird
        -> sprich Änderungen immer in beiden Teilen der If Funktionen durchführen

  bei Button clicks werden entsprechend die States gestetzt, um bei einem Update die richtigen UI Elemente
  wie bspw. Goerli Testnet processing darzustellen.
  */
  if (isLoading) {
    return <CircularProgress />;
  }
  else {
    //avoid having wrong renderData as activeObj; BigNumber Issue
    if (activeObj.id === "" && renderData[0].id !== "") setActiveObj(renderData[0])

    if (ownOrParticipate === "own") {
      return (
        <div>
          <Grid container>
            <Grid item xs={12} s={6} md={6}>
              <Box display={"flex"} mt={6} pl={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  defaultValue={pollData.own[0]}
                  value={(changedOwnOrParticipate === true) ?
                    pollData.own[0]
                    :
                    (renderType === false) ?
                      pollData.own[activeObjectLength(activeObj, false, pollData.own)]
                      :
                      pollData.own[activeObjectLength(activeObj, true, pollData.own)]}
                  onChange={(e, obj, reason) => {
                    if (obj !== null) {
                      setActiveObj(obj)
                    }
                    if (changedOwnOrParticipate === true) {
                      setChangedOwnOrParticipate(false)
                    }
                  }}
                  options={pollData.own}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: 330, color: '#FFFFFF' }}
                  renderInput={params => <TextField {...params} label="Poll" />}
                />
              </Box >
              <Box p={2}>
                <Table size="small" aria-label="share info">
                  <TableBody>
                    <TableRow><TableCell>{lang === 'en' ? "Poll name:" : "Name:"} </TableCell><TableCell id="name">{renderData[0].name}</TableCell></TableRow>
                    <TableRow><TableCell>{lang === 'en' ? "Poll question:" : "Frage:"}  </TableCell><TableCell id="question">{renderData[0].question}</TableCell></TableRow>
                    <TableRow><TableCell>{lang === 'en' ? "Poll id:" : "Umfrage ID:"}  </TableCell><TableCell id="id">{renderData[0].id}</TableCell></TableRow>
                    <TableRow><TableCell>{lang === 'en' ? "Poll creator:" : "Ersteller:"}  </TableCell><TableCell id="pollCreator">{renderData[0].objectOwner}</TableCell></TableRow>
                    <TableRow><TableCell>{lang === 'en' ? "Poll starting at:" : "Startzeit:"}  </TableCell><TableCell id="pollStartTime">{renderData[0].start}</TableCell></TableRow>
                    <TableRow><TableCell>{lang === 'en' ? " Poll ending at:" : "Endzeit:"} </TableCell><TableCell id="pollEndTime">{renderData[0].end}</TableCell></TableRow>
                    <TableRow><TableCell>{lang === 'en' ? 'Voted "yes":' : '"Ja"-Stimmen":'}  </TableCell><TableCell id="amountYes">{renderData[0].amountYes}</TableCell></TableRow>
                    <TableRow><TableCell>{lang === 'en' ? 'Voted "no":' : '"Nein"-Stimmen":'} </TableCell><TableCell id="amountNo">{renderData[0].amountNo}</TableCell></TableRow>
                    <TableRow><TableCell>{lang === 'en' ? "Total possible voters:" : "Anzahl der Wahlberechtigten:"}  </TableCell><TableCell id="voterCount">{renderData[0].voterCount}</TableCell></TableRow>
                    <TableRow><TableCell>{lang === 'en' ? "Contract owner:" : "Vertragseigentümer:"}  </TableCell><TableCell id="contractOwner">{renderData[0].contractOwner}</TableCell></TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box>
                <h1>Poll Results:</h1>
                <LinearProgressWithLabel id='progressbar' value={progressBarValue} />
              </Box>
            </Grid>
            <Grid item xs={12} s={6} md={6}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} centered>
                  <Tab label={lang === 'en' ? "New Poll" : "Neue Umfrage"} {...a11yProps(0)} />
                  <Tab label={lang === 'en' ? "Manage Voter" : "Wahlberechtigte verwalten"} {...a11yProps(1)} />
                  <Tab label={lang === 'en' ? "Cast Vote" : "Stimme abgeben"} {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <Container >
                  <Grid container justifyContent="flex-end">
                    <Box mr={4}>
                      <ContractHelpDialog lang={lang} contract={"voting"} tab={tabValue}></ContractHelpDialog>
                    </Box>
                  </Grid>
                  <TextField
                    onChange={handleNameInputChange}
                    label="Name"
                    size="small"
                    margin="dense"
                    inputRef={vCreatePollName}
                  />
                  <TextField
                    onChange={handleQuestionInputChange}
                    label={lang === "en" ? "Question" : "Frage"}
                    size="small"
                    margin="dense"
                    inputRef={vCreatePollQuestion}
                  />
                  <Box mt={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        renderInput={(props) => <TextField helperText={startTimeError}{...props} />}
                        label={lang === "de" ? "Umfragestart:" : "Poll start time:"}
                        value={startTime}
                        onChange={(newValue) => {
                          setStartTime(newValue.getTime());
                          handleStartDateInputChange(newValue)
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label={lang === "de" ? "Umfrageende:" : "Poll end time:"}
                        value={endTime}
                        onChange={(newValue) => {
                          setEndTime(newValue.getTime());
                          handleEndDateInputChange(newValue)
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Button
                    disabled={!(inputDateValid && inputNameValid && inputQuestionValid)}
                    sx={{ margin: "8px 0 0 0" }}
                    onClick={async () => {
                      try {
                        setProcessing(true)
                        await userCreatePoll(
                          vCreatePollName.current.value,
                          vCreatePollQuestion.current.value,
                          getUnixTime(startTime),
                          getUnixTime(endTime),
                        )
                        setProcessing(false)
                        setRenderType(false)
                        setNewContract(true)
                      }
                      catch {
                        setProcessing(false)
                      }
                    }}
                  >
                    {lang === 'en' ? "Create Poll" : "Umfrage erstellen"}
                  </Button>
                </Container>
                {/* <Button
                  onClick={async () => {
                    const pollCount = await userGetPollCount()
                    document.getElementById(
                      'pollCount',
                    ).innerHTML = `Total number of polls: ${pollCount}`
                  }}
                >
                  Get the total number of polls
                </Button>
                <div id="pollCount">Total number of polls: </div> */}
              </TabPanel >
              <TabPanel value={tabValue} index={1}>
                <Container>
                  <Grid container justifyContent="flex-end">
                    <Box mr={4}>
                      <ContractHelpDialog lang={lang} contract={"voting"} tab={tabValue}></ContractHelpDialog>
                    </Box>
                  </Grid>
                  <TextField
                    label="Address"
                    onChange={handleRegAddressInputChange}
                    size="small"
                    margin="dense"
                    inputRef={vRegisterUserAddress}
                    helperText={lang === "de" ? inputErrorRegAddressDe : inputErrorRegAddressEn}
                    error={!regHolderEnabled}
                  />
                  <Button
                    disabled={!regHolderEnabled}
                    sx={{ margin: "8px 0 0 0" }}
                    onClick={async () => {
                      try {
                        setProcessing(true)
                        await userRegisterNewVoter(activeObj.id, vRegisterUserAddress.current.value)
                        setProcessing(false)
                        setNewData(true)
                        setRenderType(true)
                        console.log('Voter registered!')
                      }
                      catch {
                        setProcessing(false)
                      }
                    }}
                  >
                    {lang === 'en' ? "Register New Voter" : "Wähler*in hinzufügen"}

                  </Button>
                  <br />
                  <TextField
                    onChange={handleUnregAddressInputChange}
                    label="Address"
                    size="small"
                    margin="dense"
                    inputRef={vUnregisterUserAddress}
                    helperText={lang === "de" ? inputErrorUnregAddressDe : inputErrorUnregAddressEn}
                    error={!unregHolderEnabled}
                  />
                  <Button
                    disabled={!unregHolderEnabled}
                    sx={{ margin: "8px 0 0 0" }}
                    onClick={async () => {
                      try {
                        setProcessing(true)
                        await userUnregisterNewVoter(activeObj.id, vUnregisterUserAddress.current.value)
                        setProcessing(false)
                        setNewData(true)
                        setRenderType(true)
                      }
                      catch {
                        setProcessing(false)
                      }
                    }}
                  >
                    {lang === 'en' ? "Deregister Voter" : "Wähler*in entfernen"}
                  </Button>
                </Container>
                {/* <br />
                <Button
                  onClick={async () => {
                    const voterCount = await userGetVoterCount(activeObj.id)
                    document.getElementById(
                      'voterCount',
                    ).innerHTML = `Count:  ${voterCount}`
                    console.log('Number of voters updated!')
                  }}
                >
                  Get Voter Count
                </Button>
                <div id="voterCount">Count: 0</div> */}
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                {/* <TextField
                  label="Poll number"
                  size="small"
                  margin="normal"
                  inputRef={vVotePollNumber}
                /> */}
                <Grid container justifyContent="flex-end">
                  <Box mr={4}>
                    <ContractHelpDialog lang={lang} contract={"voting"} tab={tabValue}></ContractHelpDialog>
                  </Box>
                </Grid>
                <RadioGroup
                  aria-labelledby="yes-no-radio-group"
                  defaultValue="yes"
                  name="radio-buttons-group"
                  onChange={handleRadioChange}
                >
                  <Container>
                    <FormControlLabel value="true" control={<Radio />} label={lang === "de" ? "ja" : "yes"} />
                    <FormControlLabel value="false" control={<Radio />} label={lang === "de" ? "nein" : "no"} />
                  </Container>
                </RadioGroup>
                <Button
                  disabled={!radioValue}
                  sx={{ margin: "16px 0 0 0" }}
                  onClick={async () => {
                    try {
                      setProcessing(true)
                      await userVotePoll(
                        activeObj.id,
                        radioValue,
                      )
                      setProcessing(false)
                      setNewData(true)
                      setRenderType(true)
                      console.log('Vote successfull!')
                    }
                    catch {
                      setProcessing(false)
                    }
                  }}
                >
                  {lang === "de" ? "Stimme abgeben!" : "Cast your Vote!"}
                </Button>
              </TabPanel>
              <span>
                {isProcessing === true && (
                  <Box display="flex" alignItems="center" justifyContent="center" m={3}>
                    <Alert sx={{
                      width: "fit-content",
                    }}
                      severity="warning">
                      <AlertTitle>The Goerli Testnet is processing...</AlertTitle>
                      <Box><CircularProgress /></Box>
                      The data displayed might not be up-to-date
                    </Alert>
                  </Box>

                )}
              </span>
            </Grid >
          </Grid >

        </div >
      )
    }
    else if (ownOrParticipate === "participate") {
      return (<div>
        <Grid container>
          <Grid item xs={12} s={6} md={6}>
            <Box display={"flex"} mt={6} pl={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                defaultValue={pollData.participate[0]}
                value={(changedOwnOrParticipate === true) ?
                  pollData.participate[0]
                  :
                  (renderType === false) ?
                    pollData.participate[activeObjectLength(activeObj, false, pollData.participate)]
                    :
                    pollData.participate[activeObjectLength(activeObj, true, pollData.participate)]}
                onChange={(e, obj, reason) => {
                  if (obj !== null) {
                    setActiveObj(obj)
                  }
                  if (changedOwnOrParticipate === true) {
                    setChangedOwnOrParticipate(false)
                  }
                }}
                options={pollData.participate}
                getOptionLabel={(option) => option.name}
                sx={{ width: 330, color: '#FFFFFF' }}
                renderInput={params => <TextField {...params} label="Poll" />}
              />
            </Box >
            <Box p={2}>
              <Table size="small" aria-label="share info">
                <TableBody>
                  <TableRow><TableCell>{lang === 'en' ? "Poll name:" : "Name:"} </TableCell><TableCell id="name">{renderData[0].name}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === 'en' ? "Poll question:" : "Frage:"}  </TableCell><TableCell id="question">{renderData[0].question}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === 'en' ? "Poll id:" : "Umfrage ID:"}  </TableCell><TableCell id="id">{renderData[0].id}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === 'en' ? "Poll creator:" : "Ersteller:"}  </TableCell><TableCell id="pollCreator">{renderData[0].objectOwner}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === 'en' ? "Poll starting at:" : "Startzeit:"}  </TableCell><TableCell id="pollStartTime">{renderData[0].start}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === 'en' ? "Poll ending at:" : "Endzeit:"} </TableCell><TableCell id="pollEndTime">{renderData[0].end}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === 'en' ? 'Voted "yes":' : '"Ja"-Stimmen":'}  </TableCell><TableCell id="amountYes">{renderData[0].amountYes}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === 'en' ? 'Voted "no":' : '"Nein"-Stimmen":'} </TableCell><TableCell id="amountNo">{renderData[0].amountNo}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === 'en' ? "Total possible voters:" : "Anzahl der Wahlberechtigten:"}  </TableCell><TableCell id="voterCount">{renderData[0].voterCount}</TableCell></TableRow>
                  <TableRow><TableCell>{lang === 'en' ? "Contract owner:" : "Vertragseigentümer:"}  </TableCell><TableCell id="contractOwner">{renderData[0].contractOwner}</TableCell></TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box>
              <h1>Poll Results:</h1>
              <LinearProgressWithLabel id='progressbar' value={progressBarValue} />
            </Box>
          </Grid>
          <Grid item xs={12} s={6} md={6}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange} centered>
                <Tab label={lang === 'en' ? "New Poll" : "Neue Umfrage"} {...a11yProps(0)} />
                <Tab label={lang === 'en' ? "Manage Voter" : "Wahlberechtigte verwalten"} {...a11yProps(1)} />
                <Tab label={lang === 'en' ? "Cast Vote" : "Stimme abgeben"} {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Container>
                <Grid container justifyContent="flex-end">
                  <Box mr={4}>
                    <ContractHelpDialog contract={"voting"} tab={tabValue}></ContractHelpDialog>
                  </Box>
                </Grid>
                <TextField
                  onChange={handleNameInputChange}
                  label="Name"
                  size="small"
                  margin="dense"
                  inputRef={vCreatePollName}
                />
                <TextField
                  onChange={handleQuestionInputChange}
                  label={lang === "en" ? "Question" : "Frage"}
                  size="small"
                  margin="dense"
                  inputRef={vCreatePollQuestion}
                />
                <Box mt={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      renderInput={(props) => <TextField helperText={startTimeError}{...props} />}
                      label={lang === "de" ? "Umfragestart:" : "Poll start time:"}
                      value={startTime}
                      onChange={(newValue) => {
                        setStartTime(newValue.getTime());
                        handleStartDateInputChange(newValue)
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      label={lang === "de" ? "Umfrageende:" : "Poll end time:"}
                      value={endTime}
                      onChange={(newValue) => {
                        setEndTime(newValue.getTime());
                        handleEndDateInputChange(newValue)
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Button
                  disabled={!(inputDateValid && inputNameValid && inputQuestionValid)}
                  sx={{ margin: "8px 0 0 0" }}
                  onClick={async () => {
                    try {
                      setProcessing(true)
                      await userCreatePoll(
                        vCreatePollName.current.value,
                        vCreatePollQuestion.current.value,
                        getUnixTime(startTime),
                        getUnixTime(endTime),
                      )
                      setProcessing(false)
                      setRenderType(false)
                      setNewContract(true)
                    }
                    catch {
                      setProcessing(false)
                    }
                  }}
                >
                  {lang === 'en' ? "Create Poll" : "Umfrage erstellen"}
                </Button>

              </Container>
              {/* <Button
                onClick={async () => {
                  const pollCount = await userGetPollCount()
                  if (lang === "en") {
                    document.getElementById(
                      'pollCount',
                    ).innerHTML = `Total number of polls: ${pollCount}`
                  }
                  else {
                    document.getElementById(
                      'pollCount',
                    ).innerHTML = `Anzahl aller Umfragen: ${pollCount}`
                  }
                }}
              >
                {lang === 'en' ? "Get the total number of polls" : "Anzahl aller Umfragen abfragen"}

              </Button>
              <div id="pollCount">{lang === "en" ? "Total number of polls: " : "Anzahl aller Umfragen: "}</div> */}
            </TabPanel >
            <TabPanel value={tabValue} index={1}>
              <Container>
                <Grid container justifyContent="flex-end">
                  <Box mr={4}>
                    <ContractHelpDialog contract={"voting"} tab={tabValue}></ContractHelpDialog>
                  </Box>
                </Grid>
                <TextField
                  label="Address"
                  onChange={handleRegAddressInputChange}
                  size="small"
                  margin="dense"
                  inputRef={vRegisterUserAddress}
                  helperText={lang === "de" ? inputErrorRegAddressDe : inputErrorRegAddressEn}
                  error={!regHolderEnabled}
                />
                <Button
                  disabled={!regHolderEnabled}
                  sx={{ margin: "8px 0 0 0" }}
                  onClick={async () => {
                    try {
                      setProcessing(true)
                      await userRegisterNewVoter(activeObj.id, vRegisterUserAddress.current.value)
                      setProcessing(false)
                      setNewData(true)
                      setRenderType(true)
                      console.log('Voter registered!')
                    }
                    catch {
                      setProcessing(false)
                    }
                  }}
                >
                  {lang === 'en' ? "Register New Voter" : "Wähler*in hinzufügen"}
                </Button>
                <br />
                <TextField
                  onChange={handleUnregAddressInputChange}
                  label="Address"
                  size="small"
                  margin="dense"
                  inputRef={vUnregisterUserAddress}
                  helperText={lang === "de" ? inputErrorUnregAddressDe : inputErrorUnregAddressEn}
                />
                <Button
                  disabled={!unregHolderEnabled}
                  sx={{ margin: "8px 0 0 0" }}
                  onClick={async () => {
                    try {
                      setProcessing(true)
                      await userUnregisterNewVoter(activeObj.id, vUnregisterUserAddress.current.value)
                      setProcessing(false)
                      setNewData(true)
                      setRenderType(true)
                    }
                    catch {
                      setProcessing(false)
                    }
                  }}
                >
                  {lang === 'en' ? "Deregister Voter" : "Wähler*in entfernen"}

                </Button>
              </Container>
              {/* <br />
              <Button
                onClick={async () => {
                  const voterCount = await userGetVoterCount(activeObj.id)
                  document.getElementById(
                    'voterCount',
                  ).innerHTML = `Count:  ${voterCount}`
                  console.log('Number of voters updated!')
                }}
              >
                Get Voter Count
              </Button>
              <div id="voterCount">Count: 0</div> */}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {/* <TextField
                label="Poll number"
                size="small"
                margin="normal"
                inputRef={vVotePollNumber}
              /> */}
              <Grid container justifyContent="flex-end">
                <Box mr={4}>
                  <ContractHelpDialog contract={"voting"} tab={tabValue}></ContractHelpDialog>
                </Box>
              </Grid>
              <RadioGroup
                aria-labelledby="yes-no-radio-group"
                defaultValue="yes"
                name="radio-buttons-group"
                onChange={handleRadioChange}
              >
                <Container>
                  <FormControlLabel value="true" control={<Radio />} label={lang === "de" ? "ja" : "yes"} />
                  <FormControlLabel value="false" control={<Radio />} label={lang === "de" ? "nein" : "no"} />
                </Container>
              </RadioGroup>
              <Button
                disabled={!radioValue}
                sx={{ margin: "16px 0 0 0" }}
                onClick={async () => {
                  try {
                    setProcessing(true)
                    await userVotePoll(
                      activeObj.id,
                      radioValue,
                    )
                    setProcessing(false)
                    setNewData(true)
                    setRenderType(true)
                    console.log('Vote successfull!')
                  }
                  catch {
                    setProcessing(false)
                  }
                }}
              >
                {lang === "de" ? "Stimme abgeben!" : "Cast your Vote!"}
              </Button>
            </TabPanel>
            <span>
              {isProcessing === true && (
                <Box display="flex" alignItems="center" justifyContent="center" m={3}>
                  <Alert sx={{
                    width: "fit-content",
                  }}
                    severity="warning">
                    <AlertTitle>The Goerli Testnet is processing...</AlertTitle>
                    <Box><CircularProgress /></Box>
                    The data displayed might not be up-to-date
                  </Alert>
                </Box>

              )}
            </span>
          </Grid >
        </Grid >

      </div >)
    }
  }
}
