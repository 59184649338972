import React, { useEffect, useState } from 'react'
import styles from './metamask-auth.module.css'

function isMobileDevice() {
  return 'ontouchstart' in window || 'onmsgesturechange' in window
}

async function connect(onConnected) {
  if (!window.ethereum) {
    alert('Get MetaMask!')
    return
  }

  const accounts = await window.ethereum.request({
    method: 'eth_requestAccounts',
  })

  onConnected(accounts[0])
}

async function checkIfWalletIsConnected(onConnected) {
  if (window.ethereum) {
    const accounts = await window.ethereum.request({
      method: 'eth_accounts',
    })

    if (accounts.length > 0) {
      const account = accounts[0]
      onConnected(account)
      return
    }

    if (isMobileDevice()) {
      await connect(onConnected)
    }
  }
}

/*
Funktionale Componente um MetaMask mit der Seite zu verbinden und den aktuellen Status
der Verbindung zu erkennen.
Wenn verbunden render die Componente mit einer Information zu Verbindunge
Wenn nicht verbunden render es mit einem Button, welcher ein MetaMask PopUp öffnet und
die Verbindung zur Seite ermöglicht.

Kann zwischen Mobilgerät und Desktop unterscheiden
Mobilversion jedoch nicht implementiert (nur Basics dafür)
*/
export default function MetaMaskAuth({ lang }) {
  const [userAddress, setUserAddress] = useState('')
  const [currentNetwork, setCurrentNetwork] = useState('')

  useEffect(() => {
    checkIfWalletIsConnected(setUserAddress)
  }, [])

  return userAddress ? (
    <div>
      {lang === 'en' ? 'Connected with' : 'Verbunden mit'} <Address userAddress={userAddress} />
      <br></br>
      <br></br>
    </div>
  ) : (
    <Connect setUserAddress={setUserAddress} lang={lang} />
  )
}

function Connect({ setUserAddress, lang }) {
  if (isMobileDevice()) {
    const dappUrl = '//localhost:3000/' // TODO enter your dapp URL.
    const metamaskAppDeepLink = `https://metamask.app.link/dapp/${dappUrl}`
    return (
      <a href={metamaskAppDeepLink}>
        <button className="m-4 py-2 px-4 hover:bg-gray-600 cursor-pointer font-semibold text-xl rounded bg-blue-400 text-white w-[250px]" onClick={() => connect(setUserAddress)}> {lang === 'en' ? 'Connect via Metmamask' : 'Mit Metamask verbinden'}</button>
      </a>
    )
  }

  return (
    <div>
      <button className="m-4 py-2 px-4 hover:bg-gray-600 cursor-pointer font-semibold text-xl rounded bg-blue-400 text-white w-[250px]" onClick={() => connect(setUserAddress)}>{lang === 'en' ? 'Connect via Metmamask' : 'Mit Metamask verbinden'}</button>
    </div >
  )
}

function Address({ userAddress }) {
  return (
    <span className={styles.address}>
      {userAddress.substring(0, 5)}…
      {userAddress.substring(userAddress.length - 4)}
    </span>
  )
}
