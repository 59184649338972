import React, { useState, useRef } from 'react'
import Box from '@mui/material/Box';
import ContractHelpDialog from '../../components/BasicComponents/HelpModal'
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import detectEthereumProvider from '@metamask/detect-provider'
import { TableContainer } from '@mui/material';


require('react-dom');

/*
Distribution Contract Address am Ropsten Testnet
*/
const weightedDistributionContract = '0x87e8a2c59427E61e2A042b4dd3d6ECb36CF54c6a'
/*
Distribution Contract Address am Goerli Testnet
*/
const weightedDistributionContractGoerli = '0xc99b26326d09B14178BeDB4AA4705Ba6f72c13b0'

const exampleAccs = [
  '0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2',
  '0x4B20993Bc481177ec7E8f571ceCaE8A9e22C02db',
  '0x78731D3Ca6b7E34aC0F824c42a7cC18A495cabaB',
  '0x617F2E2fD72FD9D5503197092aC168c91465E7f2',
]


/*
Beispiel Addressen um den Nutzer zu unterstützen
Beispielsweise neue Nutzer für ein Vote anzumelden
Jedoch nicht im Interface implementiert
Derzeit haben diese keine Funktion
*/

let renderData = [{
  name: "create share first",
  id: "",
  objectOwner: "",
  shareAmount: "",
  holderCount: "",
  userShareAmount: "",
  withdrawable: "",
  receivedRewards: "",
  contractOwner: "",
}]

//Definieren der contract variable, dass diese Exisitiert
let contract

//Definieren der provider variable
let provider

//Definieren der activen Share Id
let activeShareId

/*
Definieren des retrunData Objectes
Unterscheidung zwischen
  own (Voting selbst erstellt)
  particiapte (Teilnehmer bei einem Vote)
Wird unterschieden, damit nur die möglichen Funktionen im Interface dargestellt werden
*/
let returnData = {
  own: new Array(),
  participate: new Array()
}

const Web3 = require('web3')
const HDWalletProvider = require('@truffle/hdwallet-provider')
const MyContract = require('../../build/contracts/WeightedDistribution.json')

async function initializeSmartContract() {
  /*
  Ethereum Provider finden
  Funktion schaut, ob MetaMask vorhanden ist
  Wird als Schnittstelle für Blockchain Queries verwendet
  */
  provider = await detectEthereumProvider()

  //Wenn provider vorhanden
  if (provider) {
    const web3 = new Web3(provider)

    console.log('Loading contracts from adress...')
    //contract Informationen laden
    contract = new web3.eth.Contract(
      MyContract.abi,
      weightedDistributionContractGoerli,
    )
    console.log('Contracts ready!\n')
    return contract

  } else {
    console.log('Please install MetaMask!')

    return undefined
  }
}

/*
Delay Funktion um in async Codestellen zu Warten
*/
async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/*
Ether in Wei Wert umrechen
*/
function toWei(value) {
  return value * 1000000000000000000
}

/*
Wei in Ether Wert umrechnen
*/
function toEth(value) {
  return value / 1000000000000000000
}

// DISTRIBUTION CONTRACT FUNCTIONS

/*
Alles Funktionen um mit dem Smart Contract zu interagieren
Bestehen aus diversen Error Checks und entwender aus einem:
  -> .send(on chain transaktion)
  -> .call(daten von chain auslesen)

.call besitzen return wert
.send kein return wert, lösen aber ein reloading aus
*/

async function userCreateShare(name, value) {
  console.log(window.ethereum.selectedAddress)
  await contract.methods
    .createShare(name, value)
    .send({ from: window.ethereum.selectedAddress })
  console.log('Share created!')
}

async function userGetHolderCount() {
  const tmp = await contract.methods
    .shareholderCount()
    .call({ from: window.ethereum.selectedAddress })
  return tmp
}

async function userRegNewHolder(shareID, addr) {
  console.log("reg", window.ethereum.selectedAddress)
  console.log("shareID", shareID)
  await contract.methods
    .registerShareholder(shareID, addr)
    .send({ from: window.ethereum.selectedAddress })
}

async function userUnregisterShareholder(shareID, addr) {
  await contract.methods
    .unregisterShareholder(shareID, addr)
    .send({ from: window.ethereum.selectedAddress })
}

async function userGetContractOwner() {
  const owner = await contract.methods
    .contractOwner()
    .call({ from: window.ethereum.selectedAddress })
  return owner
}

async function userGetShareName() {
  const share = await contract.methods
    .share()
    .call({ from: window.ethereum.selectedAddress })
  console.log(share)
  const tmp = `${share.name}: ${share.totalSupply}`
  return tmp
}

async function userGetShareInfo(shareID) {
  const share = await contract.methods
    .allShares(shareID)
    .call({ from: window.ethereum.selectedAddress })
  //console.log(share)
  share.id = shareID
  return share
}

async function userGetTransferCount() {
  const count = await contract.methods
    .transferCount()
    .call({ from: window.ethereum.selectedAddress })
  return count
}

async function userGetBalanceOfHolder(shareID, addr) {
  const balance = await contract.methods
    .getBalanceOf(shareID, addr)
    .call({ from: window.ethereum.selectedAddress })
  return balance
}

async function userRewardShareholder(shareID, rewards) {
  rewards = toWei(rewards)

  const balance = await contract.methods
    .rewardShareholderEth(shareID)
    .send({
      from: window.ethereum.selectedAddress,
      value: rewards
    })
  return balance
}

async function userTransferHoldings(shareID, amount, destAddr) {
  await contract.methods
    .transferShareHoldings(shareID, amount, destAddr)
    .send({ from: window.ethereum.selectedAddress })
}

async function withdrawEth(shareID) {
  await contract.methods
    .withdrawEthShareholder(shareID)
    .send({ from: window.ethereum.selectedAddress })
}

async function getSumAllRewardsOfHolder(destAddr) {
  let summedRewards = await contract.methods
    .sumAllRewardsOfHolder(destAddr)
    .call({ from: window.ethereum.selectedAddress })
  return summedRewards
}

async function withdrawableFromShare(shareID, destAddr) {
  let withdrawable = await contract.methods
    .sumWithdrawableOfHolderFromShare(shareID, destAddr)
    .call({ from: window.ethereum.selectedAddress })
  return withdrawable
}

async function getAllSharesFromHolder(addr) {
  const sharesFromHolder = await contract.methods
    .allSharesFromHolder(addr)
    .call({ from: window.ethereum.selectedAddress })
  return sharesFromHolder
}

async function allSharesHolderParticipates(addr) {
  const sharesFromHolder = await contract.methods
    .allSharesHolderParticipates(addr)
    .call({ from: window.ethereum.selectedAddress })
  return sharesFromHolder
}

/*
Lädt Informaitonen auf grund der vorgegeben pollId
und fügt es zu own oder participate des returnData Objectes hinzu
*/
async function getStructuredObject(shareId, type) { //type is own or participate
  let dataObject = await userGetShareInfo(shareId)
  let balanceShareholder = await userGetBalanceOfHolder(shareId, window.ethereum.selectedAddress)
  let totalRewards = await getSumAllRewardsOfHolder(window.ethereum.selectedAddress)
  let withdrawable = await withdrawableFromShare(shareId, window.ethereum.selectedAddress)
  let contractOwner = await userGetContractOwner()

  let structuredObject = {
    name: dataObject.name,
    id: parseFloat(dataObject.id),
    objectOwner: dataObject.creator,
    shareAmount: parseFloat(dataObject.totalSupply),
    holderCount: parseFloat(dataObject.holderCount),
    userShareAmount: parseFloat(balanceShareholder),
    withdrawable: toEth(parseFloat(withdrawable)),
    receivedRewards: (totalRewards === undefined) ? 0 : toEth(parseFloat(totalRewards)),
    contractOwner: contractOwner,
  }

  returnData[type].push(structuredObject)
}

/*
Fragt alle ids von shares ab welchen der Nutzer inhaber oder teilhaber ist
Speichert diese als Array in:
  shareIdsOwn
  shareIdsParticipate
Mittels Loop werden alle notwendigen Informationen durch die Funktion
getStructuredObject(..) über die Blockchain gequeried

Solange die Daten asynchron geladen werden, wartet die Funktion
Nachdem alle Daten geladen wurden (Länge von ids und returnData Objekt stimmen überein)
werdne die geladenen Daten zurückgegeben
*/
async function getShareDataFromAddr(addr) {
  let shareIdsOwn = await getAllSharesFromHolder(addr)
  let shareIdsParticipate = await allSharesHolderParticipates(addr)

  for (let key in shareIdsOwn) {
    getStructuredObject(shareIdsOwn[key], "own")
  }

  for (let key in shareIdsParticipate) {
    getStructuredObject(shareIdsParticipate[key], "participate")
  }

  while (returnData.own.length !== shareIdsOwn.length ||
    returnData.participate.length !== shareIdsParticipate.length) {
    await delay(200)
  }
  return returnData
}

/*
Ermittelet die akutelle Id der jeweils aktiven Share
unterscheidet beim Ermitteln, ob basierend auf der Obj ID gesucht wird oder nicht
*/
function activeObjectLength(activeObj, byObjId, shareDataInfo) {
  if (byObjId === true) {
    for (let key in shareDataInfo) {
      if (shareDataInfo[key].id === activeObj.id) {
        return key
      }
    }
    return shareDataInfo.length
  }
  else {
    for (let key in shareDataInfo) {
      if (JSON.stringify(shareDataInfo[key]) === JSON.stringify(activeObj)) {
        return key
      }
    }
    return 0
  }
}

/*
Bubble Sort Funktion, um die asynchron geladnenen Daten von returnObjekt
aufgrund der Id in eine aufsteigende Reihenfolge zu übeführen
*/
function sortArray(array) {
  let swapped = true;
  while (swapped) {
    swapped = false;
    for (let j = 0; j < array.length - 1; j++) {
      if (array[j].id > array[j + 1].id) {
        let temp = array[j];
        array[j] = array[j + 1];
        array[j + 1] = temp;
        swapped = true;
      }
    }
  }
  return array;
}

/*
neu geladene Daten werden in Struktur gebracht und
in der renderData Variable gespeichert

*Hint: Eventuell falsch implementiert und könnte mittels einer State Variable mit Set Funktion ersetzt werden
       Eine der Kinderkrankheiten der ersten React Schritte
*/
function setData(data) {
  renderData = sortArray(data)
}

// TAB-PANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <h1>{children}</h1>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/*
React Componente DistributionInterface
beinhaltet alle notwenidgen Logikschritte, um das Interface entsprechend aufzubauen
basierend auf stateVariablen und useEffect hooks wird das ordentliche Laden sichergestellt
*/
export default function DistributionInterface({ lang }) {
  //variablen welche unterschiedliche states für das FrontEnd darstellen
  const [isLoading, setLoading] = useState(true); //contract daten werden geladen - bzw. sind geladen
  const [isProcessing, setProcessing] = useState(false); //daten werden verarbeitet (smart contract call)
  const [init, setInit] = useState(false) //initales daten laden abgeschlossen
  const [newContract, setNewContract] = useState(true); //contract daten wurden geupdated
  const [tabValue, setTabValue] = React.useState(0); //aktuell zu rendernder tab
  const [activeObj, setActiveObj] = useState(renderData[0]) //speichern des aktiven Objektes (ausgewählte poll daten)
  const [oldObj, setOldObj] = useState(renderData[0]) //speichern des vorherigen poll Objektes
  const [newData, setNewData] = useState(false) //neu geladene daten
  const [shareData, setShareData] = useState("") //initale share data
  const [renderType, setRenderType] = useState(false) //false -> initial und bei neuer share | true -> bei call einer share
  const [ownOrParticipate, setOwnOrParticipate] = useState("participate") //ob own oder participate angezeigt werden soll
  const [changedOwnOrParticipate, setChangedOwnOrParticipate] = useState(false) //switch von own in participate zustand

  //input field messages
  const [inputErrorNumeric, setInputErrorNumeric] = useState("");
  const [inputErrorRegAddressEn, setInputErrorRegAddressEn] = useState('(e.g. "0x1f07208802d...")');
  const [inputErrorRegAddressDe, setInputErrorRegAddressDe] = useState('(z.B. "0x1f07208802d...")');
  const [inputErrorUnregAddressEn, setInputErrorUnregAddressEn] = useState('');
  const [inputErrorUnregAddressDe, setInputErrorUnregAddressDe] = useState('');
  const [inputErrorBalance, setInputErrorBalance] = useState('');
  const [inputErrorTransferAmount, setInputErrorTransferAmount] = useState("");
  const [inputErrorTransferAddress, setInputErrorTransferAddress] = useState('');
  const [inputErrorEthAmount, setInputErrorEthAmount] = useState('Enter amount in ETH (e.g. "0.5")');

  //button value states for validation
  const [inputAmount, setInputAmount] = useState("");
  const [inputAmountValid, setInputAmountValid] = useState(false);
  const [inputName, setInputName] = useState("");
  const [inputNameValid, setInputNameValid] = useState(false);
  const [inputTransferAmount, setInputTransferAmount] = useState("");
  const [inputTransferAmountValid, setInputTransferAmountValid] = useState(false);
  const [inputTransferAddress, setInputTransferAddress] = useState("");
  const [inputTransferAddressValid, setInputTransferAddressValid] = useState(false);
  const [inputEthAmount, setInputEthAmount] = useState("");
  const [inputAddressRegHolder, setInputAddressRegHolder] = useState("");

  //button active states
  // const [createShareEnabled, setCreateShareEnabled] = useState(false);
  const [regHolderEnabled, setRegHolderEnabled] = useState(false);
  const [unregHolderEnabled, setUnregHolderEnabled] = useState(false);
  const [balanceEnabled, setBalanceEnabled] = useState(false);
  const [distributeEthEnabled, setDistributeEthEnabled] = useState(false);
  // const [withdrawEthEnabled, setWithdrawEthEnabled] = useState(false);

  const wdCreateShareName = useRef('')
  const wdCreateShareAmount = useRef('')
  const wdRegNewHolderAddr = useRef('')
  const wdTransferHoldingsAmount = useRef('')
  const wdTransferHoldingsAddress = useRef('')
  const wdUnregHolderAddr = useRef('')
  const wdGetBalanceOfHolderAddr = useRef('')
  const wdDistributeToHoldersAmount = useRef('')
  const wdGetShareInfoShareID = useRef('')


  const tabStyle = {
    maxWidth: "29%",
    paddingLeft: 12,
    paddingRight: 12,
    '@media(minWidth: 400px)': {
      paddingLeft: 9,
      paddingRight: 9,
    }
  }
  const tabLabelStyle = {
    '@media(minWidth: 400px)': {
      fontSize: "10px",
      color: "red"
    }
  }

  /*
  states updaten, wenn der Tab gewechselt wird
  -> own wenn tab value 1 ist
  -> particiapte wenn tab value 0 oder 2 ist
  */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setBalanceEnabled(false);
    setRegHolderEnabled(false);
    setInputErrorRegAddressEn('(e.g. "0x1f07208802d...")');
    setInputErrorRegAddressDe('(z.B. "0x1f07208802d...")');
    setInputErrorUnregAddressEn('');
    setInputErrorUnregAddressDe('');
    setInputErrorBalance('');
    setInputErrorNumeric('');
    setInputErrorTransferAmount('');
    setInputErrorTransferAddress('');
    lang === 'de' ? setInputErrorEthAmount('Betrag in Ethereum (z.B. "0.5")') : setInputErrorEthAmount('Enter amount in ETH (e.g. "0.5")')
    setUnregHolderEnabled(false);
    setDistributeEthEnabled(false);
    setInputTransferAddressValid(false);
    setInputTransferAmountValid(false);
    setInputAmountValid(false);
    setInputNameValid(false);
    // (activeObj.withdrawable) > 0 ? setWithdrawEthEnabled(true) : setWithdrawEthEnabled(false);
    if (newValue === 1) {
      setOwnOrParticipate("own");
      setChangedOwnOrParticipate(true)
      updateDocumentData(shareData.own[0], newData, shareData.own)
    }
    else if (newValue === 0 || newValue === 2) {
      setOwnOrParticipate("participate");
      setChangedOwnOrParticipate(true)
      updateDocumentData(shareData.participate[0], newData, shareData.participate)
    }
  };

  /*
  DOM Werte updaten:
      -> wenn ein update durch den Smart Contract stattfindet
      -> wenn tabswitch zwischen own und participate bereich stattfindet
      -> wenn ausgewähltes object (eigenes Voting) nicht dem vorherigen object entspricht; bedeutet actives object wurde geupdated
  */
  function updateDocumentData(obj, byNewUpdate, activeObj = undefined, shareDataInfo) {
    obj = (byNewUpdate === true) ? renderData[activeObjectLength(activeObj, true, shareDataInfo)] : obj
    console.log("updating document data")
    activeShareId = obj.id

    document.getElementById(
      'shareName',
    ).innerHTML = `${obj.name}`

    document.getElementById(
      'shareCreator',
    ).innerHTML = `${obj.objectOwner}`

    document.getElementById(
      'shareTotalSupply',
    ).innerHTML = `${obj.shareAmount}`

    document.getElementById(
      'shareHolderCount',
    ).innerHTML = `${obj.holderCount}`

    document.getElementById(
      'amountOwnShares',
    ).innerHTML = `${obj.userShareAmount}`

    document.getElementById(
      'withdrawable',
    ).innerHTML = `${obj.withdrawable}`

    // document.getElementById(
    //   'receivedRewards',
    // ).innerHTML = `${obj.receivedRewards}`

    document.getElementById(
      'contractOwner',
    ).innerHTML = `${obj.contractOwner}`

    setActiveObj(obj);
    // console.log("ACTIVEOBJ.ID: " + activeObj.id)
    console.log("ACTIVESHAREID: " + activeShareId)
  }

  //Handle Input Change Events
  const handleNameInputChange = (event) => {
    const { target: { value } } = event;
    if (!value) {
      setInputNameValid(false);
    }
    else {
      setInputNameValid(true);
    }
  }

  //Handle Total Supply Change
  const handleTotalSupplyInputChange = (event) => {
    const { target: { value } } = event;
    setInputErrorNumeric("");
    setInputAmount(value);
    let reg = new RegExp(/^\d+$/).test(value);
    if (!reg) {
      lang === 'en' ? setInputErrorNumeric("Please enter numeric value") : setInputErrorNumeric("Numerischen Wert eingeben");
      // setCreateShareEnabled(false);
      setInputAmountValid(false);
    }
    else {
      setInputErrorNumeric("");
      // setCreateShareEnabled(true);
      setInputAmountValid(true);
    }
  }

  //Handle Address Change
  const handleRegAddressInputChange = (event) => {
    const { target: { value } } = event;
    setInputErrorRegAddressEn("");
    setInputErrorRegAddressDe("");
    setInputAddressRegHolder(value);
    let reg = new RegExp(/^0x[a-fA-F0-9]{40}$/).test(value);
    if (!reg) {
      setInputErrorRegAddressEn('Enter valid address (e.g. "0x1f07208802d...")');
      setInputErrorRegAddressDe('Gültige Adresse angeben (z.B. "0x1f07208802d...")');
      setRegHolderEnabled(false);
    }
    else {
      setInputErrorRegAddressEn("");
      setInputErrorRegAddressDe("");
      setRegHolderEnabled(true);
    }
  }

  //Handle Amount Change
  const handleTransferAmountInputChange = (event) => {
    const { target: { value } } = event;
    setInputErrorTransferAmount("");
    setInputTransferAmount(value);
    let reg = new RegExp(/^\d+$/).test(value);
    if (!reg) {
      lang === 'en' ? setInputErrorTransferAmount("Please enter positive integer") : setInputErrorTransferAmount("Positive Zahl angeben");
      setInputTransferAmountValid(false);
    }
    else {
      setInputErrorTransferAmount("");
      setInputTransferAmountValid(true);
    }
  }

  //Handle Address Change
  const handleTransferAddressInputChange = (event) => {
    const { target: { value } } = event;
    setInputErrorTransferAddress("");
    setInputTransferAddress(value);
    let reg = new RegExp(/^0x[a-fA-F0-9]{40}$/).test(value);
    if (!reg) {
      lang === 'en' ? setInputErrorTransferAddress('Enter valid address (e.g. "0x1f07208802d...")') : setInputErrorTransferAddress('Gültige Adresse eingeben (z.B. "0x1f07208802d...")');
      setInputTransferAddressValid(false);
    }
    else {
      setInputErrorTransferAddress("");
      setInputTransferAddressValid(true);
    }
  }

  //Handle ETH amount change
  const handleEthAmountInputChange = (event) => {
    const { target: { value } } = event;
    setInputErrorEthAmount("");
    setInputEthAmount(value);
    let reg = new RegExp(/^\d+\.?\d*$/).test(value);
    if (!reg) {
      setInputErrorEthAmount('Please enter numeric value (e.g. "0.5")');
      setDistributeEthEnabled(false);
    }
    else {
      lang === 'en' ? setInputErrorEthAmount('Enter amount in ETH (e.g. "0.5")') : setInputErrorEthAmount('Betrag in ETH angeben (z.B. "0.5")');
      setDistributeEthEnabled(true);
    }
  }

  //Handle Address Change
  const handleUnregAddressInputChange = (event) => {
    const { target: { value } } = event;
    setInputErrorUnregAddressEn("");
    setInputErrorUnregAddressDe("");
    let reg = new RegExp(/^0x[a-fA-F0-9]{40}$/).test(value);
    if (!reg) {
      setInputErrorUnregAddressEn('Enter valid address (e.g. "0x1f07208802d...")');
      setInputErrorUnregAddressDe('Gültige Adresse angeben (z.B. "0x1f07208802d...")');
      setUnregHolderEnabled(false);
    }
    else {
      setInputErrorUnregAddressEn("");
      setInputErrorUnregAddressDe("");
      setUnregHolderEnabled(true);
    }
  }

  //Handle Balance Input Change
  const handleBalanceInputChange = (event) => {
    const { target: { value } } = event;
    setInputErrorBalance("");
    let reg = new RegExp(/^0x[a-fA-F0-9]{40}$/).test(value);
    if (!reg) {
      lang === 'en' ? setInputErrorBalance('Enter valid address (e.g. "0x1f07208802d...")') : setInputErrorBalance('Gültige Adresse angeben (z.B. "0x1f07208802d...")');
      setBalanceEnabled(false);
    }
    else {
      setInputErrorBalance("");
      setBalanceEnabled(true);
    }
  }

  /*
  Wenn newContract == true wird der contract zum ersten mal initial geladen
  bedeutet Objektinformationen komplett abgreifen, wenn vorhanden diese anzeigen ansonsten das sampleObject verwerden
  State Varialben entsprechend setzen
  */
  if (newContract === true) {
    initializeSmartContract()
      .then(contr => {
        contract = contr
        console.log("contract", contract)
        getShareDataFromAddr(window.ethereum.selectedAddress).then(data => {
          returnData = {
            own: [],
            participate: []
          }

          setShareData(data)

          if (data[ownOrParticipate].length !== 0) {
            setData(data[ownOrParticipate]);
            if (newContract === true && init === true) {
              console.log("new contract")
              console.log(data, ownOrParticipate)
              setActiveObj(data[ownOrParticipate][data[ownOrParticipate].length - 1])
              setNewContract(false)
            }
          }
          else {
            let sampleData = [{
              name: "create share first",
              id: "",
              objectOwner: "",
              shareAmount: "",
              holderCount: "",
              userShareAmount: "",
              withdrawable: "",
              receivedRewards: "",
              contractOwner: "",
            }]

            setData(sampleData);

            if (newContract === true && init === true) {
              setActiveObj(sampleData[0])
              setNewContract(false)
            }
          }

          setNewContract(false)
          setInit(true)
          setLoading(false);
          setProcessing(false)
        })
      })
  }

  /*
  Wenn die Daten im Contract geupdated werden, ist newData true
  Dabei müssen die neuen Daten geladen werden; neuer Call an smart contract
  Daten müssen dann entsprechend in DOM eingebettet werden
  State Variablen für weiteres rendern richtig setzen
  */
  if (newData === true) {
    getShareDataFromAddr(window.ethereum.selectedAddress).then(data => {
      returnData = {
        own: new Array(),
        participate: new Array()
      }
      console.log("second")
      if (data[ownOrParticipate].length !== 0) {
        setData(data[ownOrParticipate]);
        updateDocumentData(activeObj, true, activeObj, shareData[ownOrParticipate])
        setProcessing(false)
      }
    })
    setNewData(false)
  }

  /*
  Effect der bei neuem rendern der Seite vergleicht, ob sich das activeObject geändert hat
  Bei Änderung wird DOM elemente geupdated, und das oldObject mit activeObject gleichgesetzt
  */
  React.useEffect(() => {
    if (JSON.stringify(oldObj) !== JSON.stringify(activeObj)) {
      updateDocumentData(activeObj, false, shareData[ownOrParticipate])
      setOldObj(activeObj)
    }
  })

  /*
  Rendering des DOM;
  isLoading -> während daten geladen werden
  ownOrParticipate -> führt im FrontEnd dazu, dass im Autocomplete nur die tatsälich möglichen Votes
                      für die jeweilgen Operationen angezeigt werden

  wird per if Funktion festgelegt, da sonst kein update durch ein stateupdate durchgeführt wird
        -> sprich Änderungen immer in beiden Teilen der If Funktionen durchführen

  bei Button clicks werden entsprechend die States gestetzt, um bei einem Update die richtigen UI Elemente
  wie bspw. Goerli Testnet processing darzustellen.
  */
  if (isLoading) {
    return <CircularProgress />;
  }
  else {
    //avoid having wrong renderData as activeObj; BigNumber Issue
    if (activeObj.id === "" && renderData[0].id !== "") setActiveObj(renderData[0])

    if (ownOrParticipate === "own") {
      return (
        <div>
          <Grid container>
            <Grid item xs={12} s={6} md={6}>
              <Box display={"flex"} mt={6} pl={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  defaultValue={shareData.own[0]}
                  value={(changedOwnOrParticipate === true) ?
                    shareData.own[0]
                    :
                    (renderType === false) ?
                      shareData.own[activeObjectLength(activeObj, false, shareData.own)]
                      :
                      shareData.own[activeObjectLength(activeObj, true, shareData.own)]}
                  onChange={(e, obj, reason) => {
                    if (obj !== null) {
                      setActiveObj(obj)
                    }
                    if (changedOwnOrParticipate === true) {
                      setChangedOwnOrParticipate(false)
                    }
                  }}
                  options={shareData.own}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: 300, color: '#FFFFFF' }}
                  renderInput={params => <TextField {...params} label="Shared object" />}
                />
              </Box >
              <Box p={2}>
                <TableContainer >
                  <Table size="small" aria-label="share info">
                    <TableBody>
                      <TableRow><TableCell>{lang === 'en' ? "Shared object name:" : "Objektname:"} </TableCell><TableCell id="shareName">{renderData[0].name}</TableCell></TableRow>
                      <TableRow><TableCell>{lang === 'en' ? "Shared object owner:" : "Objekteigentümer:"} </TableCell><TableCell id="shareCreator">{renderData[0].objectOwner}</TableCell></TableRow>
                      <TableRow><TableCell>{lang === 'en' ? "Total supply:" : "Gesamtmenge:"}  </TableCell><TableCell id="shareTotalSupply">{renderData[0].shareAmount}</TableCell></TableRow>
                      <TableRow><TableCell>{lang === 'en' ? "Shareholder count:" : "Anzahl der Aktionäre:"} </TableCell><TableCell id="shareHolderCount">{renderData[0].holderCount}</TableCell></TableRow>
                      <TableRow><TableCell>{lang === 'en' ? "Own share amount:" : "Eigenanteil:"}  </TableCell><TableCell id="amountOwnShares">{renderData[0].userShareAmount}</TableCell></TableRow>
                      {/* <TableRow><TableCell>Amount of received dividends:  </TableCell><TableCell id="receivedRewards">{renderData[0].receivedRewards}</TableCell></TableRow> */}
                      <TableRow><TableCell>{lang === 'en' ? "Amount of withdrawable ETH:" : "Entnehmbarer Betrag in ETH:"}  </TableCell><TableCell id="withdrawable">{renderData[0].withdrawable}</TableCell></TableRow>
                      <TableRow><TableCell sx={{ border: 0 }}>{lang === 'en' ? "Owner of the contract:" : "Vertragseigentümer:"}  </TableCell><TableCell id="contractOwner" sx={{ border: 0 }}>{renderData[0].contractOwner}</TableCell></TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={12} s={6} md={6}>
              <Box mt={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} centered>
                  <Tab style={tabStyle} label={<span style={tabLabelStyle}>{"New Shared Object"}</span>} {...a11yProps(0)} />
                  <Tab style={tabStyle} label={<span style={tabLabelStyle}>{lang === 'en' ? "Manage Shareholder" : "Aktionäre verwalten"}</span>} {...a11yProps(0)} />
                  <Tab style={tabStyle} label={<span style={tabLabelStyle}>{"Transfer"}</span>} {...a11yProps(0)} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <Box sx={{ margin: "10px 0 0px 0" }}>
                  <Grid container justifyContent="flex-end">
                    <Box mr={4}>
                      <ContractHelpDialog lang={lang} contract={"distribution"} tab={tabValue}></ContractHelpDialog>
                    </Box>
                  </Grid>
                  <TextField
                    onChange={handleNameInputChange}
                    id="test"
                    label="Name"
                    size="small"
                    sx={{
                      margin: "10px 5px 0px 0px",
                      width: '150px',
                    }}
                    inputRef={wdCreateShareName}
                  />
                  <TextField
                    id="test"
                    onChange={handleTotalSupplyInputChange}
                    label={lang === 'en' ? "Total Supply" : "Gesamtmenge"}
                    size="small"
                    sx={{
                      margin: "10px 0px 0px 0px",
                      width: '150px',
                    }}
                    inputRef={wdCreateShareAmount}
                    // error={!inputAmountValid}
                    helperText={inputErrorNumeric}
                  />
                  <Button
                    id="createShareButton"
                    disabled={!(inputAmountValid && inputNameValid)}
                    type="submit"
                    onClick={async () => {
                      try {
                        setProcessing(true)
                        await userCreateShare(
                          wdCreateShareName.current.value,
                          wdCreateShareAmount.current.value,
                        )
                        setProcessing(false)
                        setRenderType(false)
                        setNewContract(true)
                      }
                      catch {
                        setProcessing(false);
                      }
                    }}
                    sx={{ marginTop: "10px" }}
                  >
                    {lang === 'en' ? "Create Shared Object" : "Objekt erstellen"}
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Container >
                  <Grid container justifyContent="flex-end">
                    <Box mr={4}>
                      <ContractHelpDialog lang={lang} contract={"distribution"} tab={tabValue}></ContractHelpDialog>
                    </Box>
                  </Grid>
                  <Stack spacing={1}>
                    <div>
                      <TextField
                        label={lang === 'en' ? "Address" : "Adresse"}
                        onChange={handleRegAddressInputChange}
                        helperText={lang === 'de' ? inputErrorRegAddressDe : inputErrorRegAddressEn}
                        size="small"
                        margin="dense"
                        inputRef={wdRegNewHolderAddr}
                      // sx={{ marginLeft: "17px" }}
                      // error={!regHolderEnabled}
                      />
                      <Button
                        disabled={!regHolderEnabled}
                        onClick={async () => {
                          try {
                            setProcessing(true)
                            await userRegNewHolder(
                              activeObj.id,
                              wdRegNewHolderAddr.current.value,
                            )
                            setRenderType(true)
                            setNewData(true)
                            setProcessing(false)
                            console.log('Shareholder registered!')
                          }
                          catch {
                            setProcessing(false)
                          }
                        }}
                        sx={{ marginTop: "10px" }}
                      >
                        {lang === 'en' ? "Register New Shareholder" : "Aktionär hinzufügen"}

                      </Button>
                    </div>
                    <div>
                      <TextField
                        label={lang === 'de' ? "Adresse" : "Address"}
                        onChange={handleUnregAddressInputChange}
                        helperText={lang === 'de' ? inputErrorUnregAddressDe : inputErrorUnregAddressEn}
                        size="small"
                        margin="dense"
                        inputRef={wdUnregHolderAddr}
                      // error={!unregHolderEnabled}
                      />
                      <Button
                        disabled={!unregHolderEnabled}
                        onClick={async () => {
                          try {
                            setProcessing(true)
                            await userUnregisterShareholder(
                              activeObj.id,
                              wdUnregHolderAddr.current.value,
                            )
                            setRenderType(true)
                            setNewData(true)
                            setProcessing(false)
                          }
                          catch {
                            setProcessing(false);
                          }
                        }}
                        sx={{ marginTop: "10px" }}
                      >
                        {lang === 'en' ? "Deregister New Shareholder" : "Aktionär entfernen"}
                      </Button></div>
                    <div>
                      <TextField
                        onChange={handleBalanceInputChange}
                        helperText={inputErrorBalance}
                        label={lang === 'en' ? "Address" : "Adresse"}
                        size="small"
                        margin="dense"
                        inputRef={wdGetBalanceOfHolderAddr}
                      // sx={{ marginLeft: "33px" }}
                      // error={!balanceEnabled}
                      />
                      <Button
                        disabled={!balanceEnabled}
                        onClick={async () => {
                          const balance = await userGetBalanceOfHolder(
                            activeObj.id,
                            wdGetBalanceOfHolderAddr.current.value,
                          )
                          document.getElementById(
                            'balance',
                          ).innerHTML = `Shares: ${balance}`
                          console.log('User Balance updated!')
                        }}
                        sx={{ marginTop: "10px" }}
                      >
                        {lang === 'en' ? "Get Balance of Shareholder" : "Anteile anzeigen"}

                      </Button>
                      <div id="balance">{lang === 'en' ? "Balance:" : "Anteile:"}</div></div>
                  </Stack>
                </Container>
              </TabPanel >


              <TabPanel value={tabValue} index={2}>
                <Container>
                  <Grid container justifyContent="flex-end">
                    <Box mr={4}>
                      <ContractHelpDialog lang={lang} contract={"distribution"} tab={tabValue}></ContractHelpDialog>
                    </Box>
                  </Grid>
                  <TextField
                    onChange={handleTransferAmountInputChange}
                    label="Share amount"
                    size="small"
                    sx={{
                      margin: "8px 5px 0px 0px",
                    }}
                    margin="dense"
                    inputRef={wdTransferHoldingsAmount}
                    helperText={inputErrorTransferAmount}
                  />
                  <TextField
                    onChange={handleTransferAddressInputChange}
                    label="Address"
                    size="small"
                    margin="dense"
                    inputRef={wdTransferHoldingsAddress}
                    helperText={inputErrorTransferAddress}
                  />
                  <Button
                    disabled={!(inputTransferAmountValid && inputTransferAddressValid)}
                    onClick={async () => {
                      try {
                        setProcessing(true)
                        await userTransferHoldings(
                          activeObj.id,
                          wdTransferHoldingsAmount.current.value,
                          wdTransferHoldingsAddress.current.value,
                        )
                        setRenderType(true)
                        setNewData(true)
                        setProcessing(false)
                        console.log('Transfer successfull')
                      }
                      catch {
                        setProcessing(false)
                      }
                    }}
                    sx={{ marginTop: "10px" }}
                  >
                    {lang === 'en' ? "Transfer Shares" : "Anteile übertragen"}

                  </Button>
                  <br></br>
                  <TextField
                    onChange={handleEthAmountInputChange}
                    label="Amount"
                    helperText={inputErrorEthAmount}
                    size="small"
                    margin="dense"
                    inputRef={wdDistributeToHoldersAmount}
                  />
                  <Button
                    disabled={!distributeEthEnabled}
                    onClick={async () => {
                      try {
                        setProcessing(true)
                        await userRewardShareholder(
                          activeObj.id,
                          wdDistributeToHoldersAmount.current.value,
                        )
                        setRenderType(true)
                        setNewData(true)
                        setProcessing(false)
                        console.log('All Shareholders rewarded!')
                      }
                      catch {
                        setProcessing(false)
                      }

                    }}
                    sx={{ marginTop: "10px" }}
                  >
                    {lang === 'en' ? "Distribute dividend to all Shareholder" : "Dividende an Aktionäre verteilen"}
                  </Button>
                  <br></br>
                  <Button
                    disabled={activeObj.withdrawable <= 0}
                    onClick={async () => {
                      try {
                        setProcessing(true)
                        const withdraw = await withdrawEth(
                          activeObj.id
                        )
                        setRenderType(true)
                        setNewData(true)
                        setProcessing(false)
                      }
                      catch {
                        setProcessing(false);
                      }
                    }}
                    sx={{ marginTop: "10px" }}
                  >
                    Withdraw {activeObj.withdrawable} ETH from Shared Object
                  </Button>
                </Container>
              </TabPanel>
              <span>
                {isProcessing === true && (
                  <Box display="flex" alignItems="center" justifyContent="center" m={3}>
                    <Alert sx={{
                      width: "fit-content",
                    }}
                      severity="warning">
                      <AlertTitle>The Goerli Testnet is processing...</AlertTitle>
                      <Box><CircularProgress /></Box>
                      The data displayed might not be up-to-date
                    </Alert>
                  </Box>

                )}
              </span>
            </Grid>
          </Grid>
        </div >
      )
      setChangedOwnOrParticipate(false)
    }
    else if (ownOrParticipate === "participate") {
      return (
        <div>
          <Grid container>
            <Grid item xs={12} s={6} md={6}>
              <Box display={"flex"} mt={6} pl={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  defaultValue={shareData.participate[0]}
                  value={(changedOwnOrParticipate === true) ?
                    shareData.participate[0]
                    :
                    (renderType === false) ?
                      shareData.participate[activeObjectLength(activeObj, false, shareData.participate)]
                      :
                      shareData.participate[activeObjectLength(activeObj, true, shareData.participate)]}
                  onChange={(e, obj, reason) => {
                    if (obj !== null) {
                      setActiveObj(obj)
                    }
                    if (changedOwnOrParticipate === true) {
                      setChangedOwnOrParticipate(false)
                    }
                  }}
                  options={shareData.participate}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: 300, color: '#FFFFFF' }}
                  renderInput={params => <TextField {...params} label="Shared object" />}
                />
              </Box >
              <Box p={2}>
                <TableContainer >
                  <Table size="small" aria-label="share info">
                    <TableBody>
                      <TableRow><TableCell>{lang === 'en' ? "Shared object name:" : "Objektname:"}</TableCell><TableCell id="shareName">{renderData[0].name}</TableCell></TableRow>
                      <TableRow><TableCell>{lang === 'en' ? "Shared object owner:" : "Objekteigentümer:"} </TableCell><TableCell id="shareCreator">{renderData[0].objectOwner}</TableCell></TableRow>
                      <TableRow><TableCell>{lang === 'en' ? "Total supply:" : "Gesamtmenge:"}  </TableCell><TableCell id="shareTotalSupply">{renderData[0].shareAmount}</TableCell></TableRow>
                      <TableRow><TableCell>{lang === 'en' ? "Shareholder count:" : "Anzahl der Aktionäre:"} </TableCell><TableCell id="shareHolderCount">{renderData[0].holderCount}</TableCell></TableRow>
                      <TableRow><TableCell>{lang === 'en' ? "Own share amount:" : "Eigenanteil:"}  </TableCell><TableCell id="amountOwnShares">{renderData[0].userShareAmount}</TableCell></TableRow>
                      {/* <TableRow><TableCell>Amount of received dividends:  </TableCell><TableCell id="receivedRewards">{renderData[0].receivedRewards}</TableCell></TableRow> */}
                      <TableRow><TableCell>{lang === 'en' ? "Amount of withdrawable ETH:" : "Entnehmbarer Betrag in ETH:"}  </TableCell><TableCell id="withdrawable">{renderData[0].withdrawable}</TableCell></TableRow>
                      <TableRow><TableCell sx={{ border: 0 }}>{lang === 'en' ? "Owner of the contract:" : "Vertragseigentümer:"}  </TableCell><TableCell id="contractOwner" sx={{ border: 0 }}>{renderData[0].contractOwner}</TableCell></TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={12} s={6} md={6}>
              <Box mt={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} centered>
                  <Tab style={tabStyle} label={<span style={tabLabelStyle}>{lang === 'en' ? "New Shared Object" : "Neues Objekt erstellen"}</span>} {...a11yProps(0)} />
                  <Tab style={tabStyle} label={<span style={tabLabelStyle}>{lang === 'en' ? "Manage Shareholder" : "Aktionäre verwalten"}</span>} {...a11yProps(0)} />
                  <Tab style={tabStyle} label={<span style={tabLabelStyle}>{lang === 'en' ? "Transfer" : "Transfer"}</span>} {...a11yProps(0)} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <Box sx={{ margin: "10px 0 0px 0" }}>
                  <Grid container justifyContent="flex-end">
                    <Box mr={4}>
                      <ContractHelpDialog lang={lang} contract={"distribution"} tab={tabValue}></ContractHelpDialog>
                    </Box>
                  </Grid>
                  <TextField
                    onChange={handleNameInputChange}
                    id="test"
                    label="Name"
                    size="small"
                    sx={{
                      margin: "10px 5px 0px 0px",
                      width: '150px',
                    }}
                    inputRef={wdCreateShareName}
                  />
                  <TextField
                    id="test"
                    onChange={handleTotalSupplyInputChange}
                    label={lang === 'en' ? "Total Supply" : "Gesamtmenge"}
                    size="small"
                    sx={{
                      margin: "10px 0px 0px 0px",
                      width: '150px',
                    }}
                    inputRef={wdCreateShareAmount}
                    // error={!inputAmountValid}
                    helperText={(inputErrorNumeric)}
                  />
                  <Button
                    id="createShareButton"
                    disabled={!(inputAmountValid && inputNameValid)}
                    type="submit"
                    onClick={async () => {
                      try {
                        setProcessing(true)
                        await userCreateShare(
                          wdCreateShareName.current.value,
                          wdCreateShareAmount.current.value,
                        )
                        setProcessing(false)
                        setRenderType(false)
                        setNewContract(true)
                      }
                      catch {
                        setProcessing(false);
                      }
                    }}
                    sx={{ marginTop: "10px" }}
                  >
                    {lang === 'en' ? "Create Shared Object" : "Objekt erstellen"}
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Container >
                  <Grid container justifyContent="flex-end">
                    <Box mr={4}>
                      <ContractHelpDialog lang={lang} contract={"distribution"} tab={tabValue}></ContractHelpDialog>
                    </Box>
                  </Grid>
                  <Stack>
                    <div>
                      <TextField
                        label={lang === 'en' ? "Address" : "Adresse"}
                        onChange={handleRegAddressInputChange}
                        helperText={lang === 'de' ? inputErrorRegAddressDe : inputErrorRegAddressEn}
                        size="small"
                        margin="dense"
                        inputRef={wdRegNewHolderAddr}
                        sx={{ marginLeft: "15px" }}
                      // error={!regHolderEnabled}
                      />
                      <Button
                        disabled={!regHolderEnabled}
                        onClick={async () => {
                          try {
                            setProcessing(true)
                            await userRegNewHolder(
                              activeObj.id,
                              wdRegNewHolderAddr.current.value,
                            )
                            setRenderType(true)
                            setNewData(true)
                            setProcessing(false)
                            console.log('Shareholder registered!')
                          }
                          catch {
                            setProcessing(false)
                          }
                        }}
                        sx={{ marginTop: "10px" }}
                      >
                        {lang === 'en' ? "Register New Shareholder" : "Aktionär hinzufügen"}
                      </Button>
                    </div>
                    <div>
                      <TextField
                        label={lang === 'en' ? "Address" : "Adresse"}
                        onChange={handleUnregAddressInputChange}
                        helperText={lang === 'de' ? inputErrorUnregAddressDe : inputErrorUnregAddressEn}
                        size="small"
                        margin="dense"
                        inputRef={wdUnregHolderAddr}
                      // error={!unregHolderEnabled}
                      />
                      <Button
                        disabled={!unregHolderEnabled}
                        onClick={async () => {
                          try {
                            setProcessing(true)
                            await userUnregisterShareholder(
                              activeObj.id,
                              wdUnregHolderAddr.current.value,
                            )
                            setRenderType(true)
                            setNewData(true)
                            setProcessing(false)
                            console.log('User deleted')
                          }
                          catch {
                            setProcessing(false)
                          }
                        }}
                        sx={{ marginTop: "10px" }}
                      >
                        {lang === 'en' ? "Deregister New Shareholder" : "Aktionär entfernen"}
                      </Button>
                    </div>
                    <div>
                      <TextField
                        onChange={handleBalanceInputChange}
                        helperText={inputErrorBalance}
                        label={lang === 'en' ? "Address" : "Adresse"}
                        size="small"
                        margin="dense"
                        inputRef={wdGetBalanceOfHolderAddr}
                      // error={!balanceEnabled}
                      />
                      <Button
                        disabled={!balanceEnabled}
                        onClick={async () => {
                          const balance = await userGetBalanceOfHolder(
                            activeObj.id,
                            wdGetBalanceOfHolderAddr.current.value,
                          )
                          document.getElementById(
                            'balance',
                          ).innerHTML = `Shares: ${balance}`
                          console.log('User Balance updated!')
                        }}
                        sx={{ marginTop: "10px" }}
                      >
                        {lang === 'en' ? "Get Balance of Shareholder" : "Anteile eines Aktionärs anzeigen"}
                      </Button>
                      <div id="balance">{lang === 'en' ? "Balance:" : "Anteile:"}</div>
                    </div>
                  </Stack>
                </Container>
              </TabPanel >


              <TabPanel value={tabValue} index={2}>
                <Container>
                  <Grid container justifyContent="flex-end">
                    <Box mr={4}>
                      <ContractHelpDialog lang={lang} contract={"distribution"} tab={tabValue}></ContractHelpDialog>
                    </Box>
                  </Grid>
                  <TextField
                    onChange={handleTransferAmountInputChange}
                    label={lang === 'en' ? "Share amount" : "Menge"}
                    size="small"
                    sx={{
                      margin: "8px 5px 0px 0px",
                    }}
                    margin="dense"
                    inputRef={wdTransferHoldingsAmount}
                    helperText={inputErrorTransferAmount}
                  />
                  <TextField
                    onChange={handleTransferAddressInputChange}
                    label={lang === 'en' ? "Address" : "Adresse"}
                    size="small"
                    margin="dense"
                    inputRef={wdTransferHoldingsAddress}
                    helperText={(inputErrorTransferAddress)}
                  />
                  <Button
                    disabled={!(inputTransferAmountValid && inputTransferAddressValid)}
                    onClick={async () => {
                      try {
                        setProcessing(true)
                        await userTransferHoldings(
                          activeObj.id,
                          wdTransferHoldingsAmount.current.value,
                          wdTransferHoldingsAddress.current.value,
                        )
                        setRenderType(true)
                        setNewData(true)
                        setProcessing(false)
                        console.log('Transfer successfull')
                      }
                      catch {
                        setProcessing(false)
                      }
                    }}
                    sx={{ marginTop: "10px" }}
                  >
                    {lang === 'en' ? "Transfer Shares" : "Anteile übertragen"}
                  </Button>
                  <br></br>
                  <TextField
                    onChange={handleEthAmountInputChange}
                    label={lang === 'en' ? "Amount" : "Betrag"}
                    helperText={inputErrorEthAmount}
                    size="small"
                    margin="dense"
                    inputRef={wdDistributeToHoldersAmount}
                  />
                  <Button
                    disabled={!distributeEthEnabled}
                    onClick={async () => {
                      try {
                        setProcessing(true)
                        await userRewardShareholder(
                          activeObj.id,
                          wdDistributeToHoldersAmount.current.value,
                        )
                        setRenderType(true)
                        setNewData(true)
                        setProcessing(false)
                      }
                      catch {
                        setProcessing(false)
                      }
                    }}
                    sx={{ marginTop: "10px" }}
                  >
                    {lang === 'en' ? "Distribute dividend to all Shareholder" : "Dividende an Aktionäre verteilen"}

                  </Button>
                  <br></br>
                  <Button
                    disabled={activeObj.withdrawable <= 0}
                    onClick={async () => {
                      try {
                        setProcessing(true)
                        const withdraw = await withdrawEth(
                          activeObj.id
                        )
                        setRenderType(true)
                        setNewData(true)
                        setProcessing(false)
                      }
                      catch {
                        setProcessing(false)
                      }
                    }}
                    sx={{ marginTop: "10px" }}
                  >
                    {lang === 'en' ? "Withdraw " : ""}
                    {activeObj.withdrawable}
                    {lang === 'en' ? " ETH from Shared Object" : " ETH von Aktie abheben"}
                  </Button>
                </Container>
              </TabPanel>
              <span>
                {isProcessing === true && (
                  <Box display="flex" alignItems="center" justifyContent="center" m={3}>
                    <Alert sx={{
                      width: "fit-content",
                    }}
                      severity="warning">
                      <AlertTitle>The Goerli Testnet is processing...</AlertTitle>
                      <Box><CircularProgress /></Box>
                      The data displayed might not be up-to-date
                    </Alert>
                  </Box>

                )}
              </span>
            </Grid>
          </Grid>
        </div >
      )
      setChangedOwnOrParticipate(false)
    }
  }
}
