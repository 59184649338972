import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import styles from './contractInterface.module.css'
import Info from "../../pages/ContractInteraction/info";
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import VideoEmbedding from "../../components/MultiStep/VideoEmbedding";

import DistributionInterface from "../../pages/ContractInteraction/distributionInterface";
import VotingInterface from "../../pages/ContractInteraction/votingInterface";
import StreamingInterface from "../../pages/ContractInteraction/streamingInterface";

require('react-dom');

/*
Definition der contacts inklusive lable und id
*/
const contracts = [
  {
    label: 'Voting Contract',
    id: 2
  },
  {
    label: 'Share/Dividend Contract',
    id: 3
  },
  {
    label: 'Streaming Contract',
    id: 4
  },
]

/*
unterschiedlichen ContractIDs einen Variablennamen zuweisen
*/
const votingContract = 2
const distributionContract = 3
const streamingContract = 4

export default function ContractInterface({ lang, contract }) {
  /*
  currContract festlegen, wird durch Buttonclick als Argument mitegegeben -> 'contract'
  */
  const [currContract, setContractFunction] = useState(contract)


  /*
  switch statement um den ausgwählten Contract zu rendern
  Eine Contract Seite besteht aus:
      -> Autocomplete Element (auswahl des Contracts)
      -> Info Element mit initialen Informationen zum Contract
      -> Dem jeweiligen Contract Interface
      -> VideoElement
  */
  switch (currContract) {
    case votingContract:
      return (
        <div>
          <div className={styles.autocomplete}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(e, obj, reason) => {
                if (obj !== null) {
                  setContractFunction(obj.id)
                }
                else {
                  setContractFunction(0)
                }
              }}
              defaultValue={{
                label: 'Voting Contract',
                id: 2,
              }}
              options={contracts}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: 300 }}
              renderInput={params => <TextField {...params} label="Contract" />}
            />
          </div>
          <Info
            lang={lang}
            step={2}
            contract={votingContract}
          />
          <VotingInterface lang={lang} />
          <VideoEmbedding state={2} contract={0} lang={lang} />
        </div>
      )
    case distributionContract:
      return (
        <div>
          <div className={styles.autocomplete}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(e, obj, reason) => {
                if (obj !== null) {
                  setContractFunction(obj.id)
                }
                else {
                  setContractFunction(0)
                }
              }}
              defaultValue={{
                label: 'Share/Dividend Contract',
                id: 3,
              }}
              options={contracts}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: 300 }}
              renderInput={params => <TextField {...params} label="Contract" />}
            />
          </div>
          <Info
            lang={lang}
            step={2}
            contract={distributionContract}
          />
          <DistributionInterface lang={lang} />
          <VideoEmbedding state={2} contract={1} lang={lang} />
        </div>
      )
    case streamingContract:
      return (
        <div>
          <div className={styles.autocomplete}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(e, obj, reason) => {
                if (obj !== null) {
                  setContractFunction(obj.id)
                }
                else {
                  setContractFunction(0)
                }
              }}
              defaultValue={{
                label: 'Streaming Contract',
                id: 4,
              }}
              options={contracts}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: 300 }}
              renderInput={params => <TextField {...params} label="Contract" />}
            />
          </div>
          <Info
            lang={lang}
            step={2}
            contract={streamingContract}
          />
          <StreamingInterface lang={lang} />
          <Box m={2}>
            <Divider></Divider>
          </Box>
          {/* Video noch nicht produziert
           <VideoEmbedding state={2} contract={2} lang={lang} /> */}
        </div>
      )
    default:
      return (
        <div>
          <h1>{lang === "de" ? "Contract auswählen:" : "Select contract:"}</h1>
          <div className={styles.autocomplete}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(e, obj, reason) => {
                setContractFunction(obj.id)
              }}
              options={contracts}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                width: 300,
              }}
              renderInput={params => <TextField {...params} label="Contract" />}
            />
          </div>
          <Info
            lang={lang}
            step={2}
            contract={1}
          />
        </div>
      )
  }
}
