import React, { useState } from 'react';

import styles from './videoembedding.module.css'

import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(4),
  },
}));

/*
Links für die unterschiedlichen Steps zu den Videos
*/
let links = [
  "lmwwzWEzTtI",  //Step Install Metamask
  "2ZI1vCXgxx4",  //Step get TEst Eth
  ["zVZcJF7F_7U", "adhXI850E7w", "U3Dt08bsguE"] //Step Contract Interaction: [voting, distribution, streaming]
]


const VideoEmbedding = ({ state, contract, lang }) => {
  let embedId = (contract === undefined) ?
    links[state]
    :
    links[state][contract]

  const [show, setShow] = useState(false);

  if (!show) {
    return (
      <Root>
        <Divider textAlign="left">
          <button className={styles.button} onClick={() => setShow(prev => !prev)}>{(lang === "de") ? "Video Tutorial anzeigen" : "show video tutorial"} {(show === false) ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</button>
        </Divider>
      </Root>
    )
  }
  else {
    return (
      <div>
        <Root>
          <Divider textAlign="left">
            <button className={styles.button} onClick={() => setShow(prev => !prev)}>{(lang === "de") ? "Video Tutorial verbergen" : "hide video tutorial"}  {(show === false) ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</button>
          </Divider>
        </Root>

        <Container>
          <iframe
            width="1280"
            height="720"
            src={`https://www.youtube.com/embed/${embedId}?REL=0&VQ=HD1080`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Container>

      </div>

    )

  }
}

export default VideoEmbedding
