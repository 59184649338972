import React from 'react'

export default function Portfolio({ lang }) {
    // useEffect(() => {
    //     window.onscroll = handleScrolle
    // }, [])
    // const handleScrolle = (e) => {
    //     var position = containerRef.current.getBoundingClientRect();
    //     if (position.top < window.innerHeight && position.bottom >= 0) {
    //         console.log("Portfolio")
    //         containerRef.current.classList.add("fade");
    //     } else {
    //         containerRef.current.classList.remove("fade");
    //     }
    // }
    // var containerRef = useRef(null);
    const data = [
        {
            heading: lang === 'en' ? 'Individual Smart Contract development' : 'Individuelle Smart Contract Entwicklung',
            text: lang === 'en' ? 'Do you already have a concrete idea that you want to implement with a smart contract? We are specialists in the implementation of smart contracts on an EVM (Ethereum) or NVM (Neo) basis. In addition, we support you in implementing the necessary interfaces to be able to interact with your smart contract.'
                : 'Haben Sie bereits eine konkrete Idee, welche Sie mit einem Smart Contract realisieren möchten? Wir sind Spezialisten in der Implementierung von Smart Contract auf EVM (Ethereum) oder NVM (NEO) Basis. Zusätzlich unterstützen wir Sie in der Implementierung notwendiger Interfaces, um mit dem Smart Contract interagieren zu können.'
        },
        {
            heading: lang === 'en' ? 'Enterprise Blockchains' : 'Enterprise Blockchains',
            text: lang === 'en' ? 'Operating your own (semi)-private enterprise blockchain infrastructure can be the ideal solution for many use cases. The use of a blockchain is particularly worthwhile for issues relating to decentralization, an unchangeable transaction history or the digital representation of a real asset. We would be happy to help you select the right blockchain for you.'
                : 'Das Betreiben einer eignen (semi)-privaten Blockchain Infrastruktur kann die ideale Lösung für diverse Anwendungen sein. Das Nutzen einer eigenen Blockchain kann vor allem für Anwendungsfälle sinnvoll sein, welche die Bereiche der Dezentralisierung, Sicherstellung der Historie oder die Repräsentation von realen Assets betreffen. Lassen Sie uns helfen die richtige Wahl für Sie zu treffen.'
        },
        {
            heading: lang === 'en' ? 'Business Case Analysis' : 'Business Case Analyse',
            text: lang === 'en' ? 'Would you like to evaluate the possibilities that blockchain technology offers your company? Together we analyze your system architecture and workflows to find potential for improvements. Even if you already have a concrete idea, but would like a second opinion before the technical implementation, we are the right partner.'
                : 'Sie möchten die Möglichkeiten der Blockchain in Bezug auf Ihr Unternehmen erkunden? Wir würden uns freuen gemeinsam mit Ihnen die Systemarchitektur und Arbeitsabläufe in Ihrem Unternehmen zu analysieren und Chancen für Optimierungen auf Blockchain Basis zu finden. Auch wenn Sie bereits eine konkrete Idee besitzen, aber eine zweite Meinung vor der technischen Umsetzung benötigen, sind wir der richtige Ansprechpartner.'
        }
    ]
    return (
        <div className="bg-sectino1">
            <div id='portfolio' className="hoverHidden portfolio-container-div mt-[80px] text-white">
                <h1 className="text-4xl font-bold text-center py-8 animate-box"> {lang === 'en' ? 'Our Portfolio' : 'Unser Portfolio'} </h1>
                <div className="grid md:grid-cols-3 gap-x-10 sm:grid-cols-1">
                    {
                        data.map((dat, index) => {
                            return (
                                <div key={index}>
                                    <div className="text-left text-3xl mx-2">{dat.heading}</div>
                                    <div className="text-justify text-lg my-6 mx-2">{dat.text}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>

    )
}
