import * as React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import SplitText from './SplitText'


const tabNamesEn = {
  voting: {0:"New Poll",
  1: "Manage Voter",
  2: "Cast Vote"},
  distribution: {0:"New shared object",
  1: "Manage Shareholder",
  2: "Transfer"},
  streaming: {0:"New Stream",
  1: "Manage active stream",
  2: "Manage finished stream"}
  }

  const tabNamesDe = {
    voting: {0:"Poll erstellen",
    1: "Wahlberechtigte verwalten",
    2: "Stimme abgeben"},
    distribution: {0:"Neues Objekt erstellen",
    1: "Aktionäre verwalten",
    2: "Transfer"},
    streaming: {0:"Stream erstellen",
    1: "Aktive Streams verwalten",
    2: "Beendete Streams verwalten"}
    }


const contractsEn = {
  voting: {name:"Voting Contract",
  0:{ note: "Note, that the data entered cannot be changed afterwards.", text: "In this tab you have the possibility to create a new <b>poll</b>.</br></br>After the Goerli testnetwork has finished processing, the <b>poll</b> should automatically appear in the drop down menu 'Poll'.</br></br>When creating a <b>poll</b>, you are automatically assigned to be the <b>poll</b> owner. This makes you elligible to register and unregister other voters."},
  1:{ note: "Only the owner of a <b>poll</b> is able to perform tasks in this tab.", text: "<b>Register</b> and <b>unregister</b> other users for a specific poll so they can cast their vote.<br>When unregistering another voter, their vote will be lost."},
  2:{ note: "Note, that you can only vote while the <b>poll</b> is active", text: "Select the <b>poll</b> in the drop down menu and cast your vote!"}
  }, 
  distribution: {name:"Distribution Contract",
  0:{ note: "Note, that the name and total supply values, cannot be changed afterwards.", text: "In this tab you have the possibility to create a new <b>shared object</b>.<br><br>After the Goerli testnetwork has finished processing, the <b>shared object</b> should automatically appear in the drop down menu 'Shared object'.<br><br>When creating an <b>object</b>, your are automatically assigned to be the object owner. This makes you elligible to register and unregister other shareholders."},
  1:{ note: "Only the owner of a shared object is able to perform tasks in this tab", text: "<b>Register</b> and <b>unregister</b> other users for a specific shared object so you can later transfer holdings of that object to the new shareholder.<br><br>When unregistering another shareholder, first make sure, that the user has no holdings of that specific objects, otherwise this transaction will fail."},
  2:{ note: "Make sure to select the shared object you want to interact with in the drop down menu.", text: "Transfer a specific amount of holdings from the shared object selected, to another registered shareholder.<br><br>Distribute a specific amount of Ether to all shareholders of the selected object. The amount of Ether every shareholder will receive is relative to the amount of shares they own.<br><br>Shareholders can then withdraw their gains from every object to transfer them to their wallet."}
  },
  streaming: {name:"Streaming Contract",
  0:{ note: "Both parties can cancel the stream at any time", text: "In this tab you have the possibility to create a new <b>stream</b>.<br><br>After the Goerli testnetwork has finished processing, the <b>stream</b> should automatically appear in the drop down menu 'Streams'."},
  1:{ note: "Make sure to select the stream you want to interact with in the drop down menu.", text: "In this tab it is possible to <b>cancel</b>  any incomming or outgoing active stream. <br><br>This will immediatly stop the stream and the recipient will only be able to withdraw the amount of ETH that have been transferred before the point of cancellation."},
  2:{ note: "Make sure to select the stream you want to interact with in the drop down menu.", text: "In this tab you can <b>withdraw</b> the received ETH from any finished or cancelled incomming stream."}
  }
}

const contractsDe = {
  voting: {name:"Voting Contract",
  0:{ note: "Alle angegebenen Daten können im Nachhinein nicht geändert werden.", text: "In diesem Tab haben Sie die Möglichkeit eine <b>Umfrage</b> zu erstellen.</br></br>Nachdem die Verarbeitung abgeschlossen ist, sollte die Umfrage automatisch im DropDown Menu 'Poll' erscheinen.</br></br>Beim Erstellen einer Umfrage, werden Sie automatisch als Umfrageeigentümer registriert. Dadurch haben Sie die Möglichkeit andere Wahlberechtigte für diese Umfrage zu registrieren."},
  1:{ note: "Nur der Eigentümer einer Umfrage kann Aktionen in diesem Tab ausführen.", text: "In diesem Tab können Sie Wahlberechtigte einer Umfrage <b>hinzufügen</b> oder <b>entfernen</b>.<br>Beim Entfernen eines Wahlberechtigten, geht dessen Stimme verloren."},
  2:{ note: "Abstimmen ist nur möglich, solange die <b>Umfrage</b> aktiv ist", text: "Wählen Sie die <b>Umfrage</b> im DropDown-Menü aus und geben Sie Ihre Stimme ab!"}
  }, 
  distribution: {name:"Distribution Contract",
  0:{ note: "Name und Gesamtmenge können im Nachhinein <b>nicht</b> geändert werden", text: "In diesem Tab können Sie ein neues Objekt erstellen.<br><br>Nachdem die Verarbeitung abgeschlossen ist, sollte das Objekt automatisch im DropDown Menü 'Shared Object' erscheinen.<br><br>Beim Erstellen eines <b>Objecktes</b> werden Sie automatisch als Objekteigentümer registriert. Dadurch haben Sie die Möglichkeit andere Nutzer als Aktionäre dieses Objekts hinzuzufügen oder zu entfernen."},
  1:{ note: "Nur der Eigentümer des Objektes kann Aktionen in diesem Tab ausführen.", text: "In diesem Tab können Sie Aktionäre zu einem Objekt <b>hinzufügen</b> oder <b>entfernen</b>, damit Sie später Anteile dieses Objekts an sie übertragen können.<br><br> Aktionäre können nur entfernt werden, wenn sie keine Anteile mehr am Objekt besitzen."},
  2:{ note: "Wählen Sie das entsprechende Objekt aus, bevor Sie eine Aktion ausführen.", text: "Übertragen Sie eine gewisse Menge an Anteile eines Objekts an einen anderen Aktionär.<br><br>Verteilen Sie eine Dividende unter allen Aktionären des ausgewählten Objekts. Jeder Aktionär bekommt dann seinen Teil der Ethereum entsprechend der Menge an Anteilen die er an diesem Objekt hält.<br><br>Aktionäre können diese Dividende anschließend von den einzelnen Objekten beheben, um sie auf ihr Wallet zu transferieren."}
  },
  streaming: {name:"Streaming Contract",
  0:{ note: "Beide Parteien können den Stream jederzeit abbrechen.", text: "In diesem Tab können Sie einen neuen <b>Stream</b> erstellen.<br><br>Nachdem die Verarbeitung abgeschlossen ist, sollte der Stream automatisch im DropDown Menü 'Streams' erscheinen."},
  1:{ note: "Wählen Sie den entsprechenden Stream aus, bevor Sie eine Aktion durchführen.", text: "In diesem Tab können Sie aktive Streams abbrechen. <br><br> Dadurch wird der Stream sofort abgebrochen und der Empfänger kann nur die Menge an ETH beheben, die bis zu diesem Zeitpunkt gestreamt wurden."},
  2:{ note: "Wählen Sie den entsprechenden Stream aus, bevor Sie eine Aktion durchführen.", text: "In diesem Tab können Sie die Ethereum von allen beendeten Streams beheben."}
  }
}

function SimpleDialog(props) {
  const { onClose, contract, tab, open, lang } = props;

  const handleClose = () => {
    onClose();
  };


  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle align='center'>{lang === "de" ? tabNamesDe[contract][tab] : tabNamesEn[contract][tab]}</DialogTitle>
      <Divider />
        <Typography component={'span'} p={2}>
          <SplitText info={lang ==="de" ? contractsDe[contract][tab] : contractsEn[contract][tab]}/>
        </Typography>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tab: PropTypes.number.isRequired,
  contract: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired
};

export default function ContractHelpDialog(props) {
  const [open, setOpen] = React.useState(false);
  const {lang, contract,tab} = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(tab)
  console.log(contract)
  return (
    <div>
    {lang === "en" && (
      <div>
      <Tooltip title="Need help?">
          <IconButton tooltip="Need help?" onClick={handleClickOpen}>
              <HelpOutlineIcon tooltip="Need help?"  sx={{ fontSize: 30 }}/>
          </IconButton>
      </Tooltip>
      <SimpleDialog
          contract={contract}
          tab={tab}
          open={open}
          onClose={handleClose}
      />
      </div>
  )}

  {lang === "de" && (
    <div>
    <Tooltip title="Need help?">
        <IconButton tooltip="Need help?" onClick={handleClickOpen}>
            <HelpOutlineIcon tooltip="Need help?"  sx={{ fontSize: 30 }}/>
        </IconButton>
    </Tooltip>
    <SimpleDialog
        contract={contract}
        tab={tab}
        open={open}
        onClose={handleClose}
        lang={lang}
    />
    </div>
    )}
</div>
  );
}
